
export default class Gobject {
    constructor(id, name) {
        this.id = id;
        this.name = name;
    }
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    static createInstance(data)
    {
        // @todo check data format and throw an error if its wrong
        return new Gobject(data.id, data.name);
    }
}
