import { makeStyles } from "@material-ui/core/styles";
import clx from 'classnames';



const useStyles = makeStyles((theme) => ({
  root: {
    margin: '-20px -10px',
    fontSize: '22px',
    fontWeight: 400,
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
    fontFamily: 'Montserrat, sans-serif',
    color: '#2b2b2b',
    lineHeight: '26px',
    background: '#D9D9D9',
    borderRadius: '15px',
    
    "@media (max-width: 600px)": {
      fontSize: "16px",
      lineHeight: "20px"
    }
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  arrow: {
    background: '#F5F5F5',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '50%',
    width: '33px',
    height: '33px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#484848',
    cursor: 'pointer',
    paddingLeft: '3px',
    '&:hover': {
      color: '#353535',
      boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.33)',
      background: '#FFFFFF',
    }
  },
  turn: {
    transform: 'rotate(-180deg)',
  },
  miss: {
    fontSize: '16px',
    fontWeight: 500,
    width: '120px',
    height: '33px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '15px',
    background: '#F5F5F5',
    color: '#484848',
    cursor: 'pointer',
    '&:hover': {
      color: '#353535',
      boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.33)',
      background: '#FFFFFF',
    }
  },
  next: {
    transform: 'translateX(2px)'
  },
  prev: {
    transform: 'translateX(-2px)'
  },
}));

const Step = ({ onAction, children, isFirst, isLast }) => {

  const styles = useStyles();

  const onSelect = (action) => () => onAction(action);

  return (
    <div className={styles.root}>
      <span>{children}</span>
      <div className={styles.footer}>
        {!isFirst && (
          <div className={clx(styles.arrow, styles.prev)} onClick={onSelect('prev')} style={{ transform: 'translateX(-2px)', paddingRight: '6.5px',  }}>
            <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.5 3.49906L5.375 11.3562L13.5 19.2133L11.875 22.3562L0.499999 11.3562L11.875 0.356201L13.5 3.49906Z" fill="currentColor"/>
            </svg>
          </div>
        )}
        <div className={styles.miss} onClick={onSelect('miss')}>
          Пропустить
        </div>
        {!isLast && (
          <div className={clx(styles.arrow, styles.next)} onClick={onSelect('next')} style={{ transform: 'translateX(2px)' }}>
            <svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 19.0268L8.125 11.1697L0 3.31253L1.625 0.169678L13 11.1697L1.625 22.1697L0 19.0268Z" fill="currentColor"/>
            </svg>
          </div>
        )}
      </div>
    </div>
  )
};

export {
  Step
};
