export default class Test {
    constructor(id) {
        this.id = id;
    }

    getTestUrl() {
        return `https://${process.env.REACT_APP_DOMAIN_WWW}/t/` + this.id;
    }

    getTestResultsUrl() {
        return `https://${process.env.REACT_APP_DOMAIN_WWW}/r/` + this.id;
    }
}