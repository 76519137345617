

import React from 'react';

import World from './World/World';
import Filter from './Filter/Filter';
import Result from './Result/Result';
import Skeleton from '@material-ui/lab/Skeleton';
import shuffle from 'shuffle-array';
import {layers} from "./World/tiles";
import { nanoid } from 'nanoid';
import Test from './Model/Test';

// import {AppBar, Toolbar, Typography} from "@material-ui/core";

import Box from '@material-ui/core/Box';
import {FilterFactory} from "./Filter/Factory/FilterFactory";
import {REGION_DEFAULT} from "./Filter/Entity/Region";
import {CATEGORY_DEFAULT} from "./Filter/Entity/Category";

class ModeEducation extends React.Component {
    constructor(props) {
        super(props);
        this.filterService = (new FilterFactory()).createFilterService();
        this.state = {
            loadedGobjectsData: false, // changed in handleDoneFiltering
            displayFilter: true, // changed in handleDoneFiltering
            displayQuestion: false,
            displayResult: false,
            selectedCategories: {},
            selectedGobjects: {},
            selectedTiles: {
                relief: {
                    checked: true,
                    disabled: false,
                },
                maps_for_free: {
                    checked: false,
                    disabled: false,
                },
                staen_terrain: {
                    checked: false,
                    disabled: false,
                },
                deane_kensok: {
                    checked: false,
                    disabled: false,
                },
                russia_conic_projection: {
                    checked: false,
                    disabled: false,
                },
                water: {
                    checked: true,
                    disabled: false,
                },
                admin: {
                    checked: false,
                    disabled: false,
                },
            },
            filter: {}, // filled in componentDidMount & componentDidUpdate
            region: '', // filled in componentDidMount
            category: '', // filled in componentDidMount
            // properties must name in this.tileData
            url: null,
            isShowShortUrl: false,
            shortUrl: '',
            buttonShortUrl: {
                text: 'Сгенерировать ссылку',
                isDisabled: false
            },
            selectedGobjectsAndRegion: []
        };
        this.timer = {};
        this.regions = []; // эти данные есть в filter, но нам понадобился порядок для сортировки на клиенте, поэтому пока так
        this.categories = []; // эти данные есть в filter, но нам понадобился порядок для сортировки на клиенте, поэтому пока так
        this.tileReliefLabels = {
            relief: 'Рельеф (MaptoMind)',
            maps_for_free: 'Рельеф (maps-for-free)',
            staen_terrain: 'Рельеф (Stamen Terrain)',
            deane_kensok: 'Рельеф (Deane Kensok)',
            russia_conic_projection: 'Россия (коническая проекция)',
        };
        this.tileLabels = {
            water: 'Водные объекты',
            admin: 'Административные границы',
        };
        this.tileData = layers;
        this.gobjects = [];
        this.attempts = [];
        this.handleDoneFiltering = this.handleDoneFiltering.bind(this);
        this.handleDoneAnswering = this.handleDoneAnswering.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleRetake = this.handleRetake.bind(this);
        this.handleMistakesOnly = this.handleMistakesOnly.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handleCategoryClick = this.handleCategoryClick.bind(this);
        this.handleCategoryPick = this.handleCategoryPick.bind(this);
        this.handleCategoryUnpick = this.handleCategoryUnpick.bind(this);
        this.handleGobjectPick = this.handleGobjectPick.bind(this);
        this.handleGobjectUnpick = this.handleGobjectUnpick.bind(this);
        this.handleMapLayerPick = this.handleMapLayerPick.bind(this);
        this.handleMapLayerUnpick = this.handleMapLayerUnpick.bind(this);
        this.handleCreateTest = this.handleCreateTest.bind(this);
        this.handleGenerateFastLink = this.handleGenerateFastLink.bind(this);
    }
    componentDidMount() {
        const categories = this.filterService.fetchAllCategories();
        const regionCollectionInitial = this.filterService.fetchRegionCollectionInitial();
        Promise.all([regionCollectionInitial, categories]).then(([regionCollectionInitial, categories]) => {
            this.regions = regionCollectionInitial.getNames();
            this.categories = categories.getNames();

            const filter = {};
            this.regions.forEach(region => {
                filter[region] = {};
            });
            regionCollectionInitial.getDefaultRegion().getCategories().getNames().forEach(category => {
                filter[regionCollectionInitial.getDefaultRegion().name][category] = {};
            });
            regionCollectionInitial.getDefaultRegion().getCategories().getDefaultCategory().getGobjects().asArray().forEach(gobject => {
                filter[regionCollectionInitial.getDefaultRegion().name][regionCollectionInitial.getDefaultRegion().getCategories().getDefaultCategory().name][gobject.id] = gobject.name;
            });

            this.setState({
                region: regionCollectionInitial.getDefaultRegion().name,
                category: regionCollectionInitial.getDefaultRegion().getCategories().getDefaultCategory().name,
                filter: filter,
            });
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const region = this.state.region;

        if (prevState.category && prevState.category !== this.state.category && !Object.keys(this.state.filter[region][this.state.category] ?? {}).length) {
            const category = this.state.category;
            // @todo этот код не должен отправлять запрос, если он уже отправлен
            this.filterService.fetchGobjectCollection(region, category)
                .then(gobjectCollection => {
                    this.setState((state) => {
                        const filter = Object.assign({}, state.filter);
                        if (!filter[region][category]) {
                            filter[region][category] = {};
                        }
                        gobjectCollection.asArray().forEach(gobject => {
                            filter[region][category][gobject.id] = gobject.name;
                        });

                        // если стоит галочка напротив категории, то нужно проставить галочки на всех объектах категории
                        let selectedGobjects = Object.assign({}, state.selectedGobjects);
                        let selectedGobjectsAndRegion = JSON.parse(JSON.stringify(state.selectedGobjectsAndRegion));
                        if (state.selectedCategories[region]?.[category]) {
                            for (const [id, name] of Object.entries(filter[region][category])) {
                                selectedGobjects[id] = name;
                                selectedGobjectsAndRegion.push({
                                    'id': id,
                                    'name': name,
                                    'region': state.region
                                });
                            }
                        }

                        return {
                            filter: filter,
                            selectedGobjects: selectedGobjects,
                            selectedGobjectsAndRegion: selectedGobjectsAndRegion
                        }
                    });

                });
        }
        if (prevState.region && prevState.region !== region && !Object.keys(this.state.filter[region] ?? {}).length) {
            // после запроса придется переписать category
            let category = this.state.category;
            this.filterService.fetchCategoryCollection(region)
                .then(categoryCollection => {
                    category = categoryCollection.getDefaultCategory().name;
                    this.setState((state) => {
                        const filter = Object.assign({}, state.filter);
                        categoryCollection.getNames().forEach(categoryName => {
                            // обновлять если не пусто
                            if (!Object.keys(filter[region][categoryName] ?? {}).length) {
                                filter[region][categoryName] = {};
                            }
                        });

                        return {
                            category: category,
                            filter: filter
                        }
                    });

                    return this.filterService.fetchGobjectCollection(region, category);
                }).then(gobjectCollection => {
                    this.setState((state) => {
                        const filter = Object.assign({}, state.filter);
                        // вот здесь category может быть уже переписанная
                        if (!filter[region][category]) {
                            filter[region][category] = {};
                        }
                        // обновлять если не пусто
                        if (!Object.keys(filter[region][category] ?? {}).length) {
                            gobjectCollection.asArray().forEach(gobject => {
                                filter[region][category][gobject.id] = gobject.name;
                            });
                        }

                        return {
                            filter: filter
                        }
                    });
                });
        }

        if (prevState.selectedGobjectsAndRegion.length !== this.state.selectedGobjectsAndRegion.length) {
            let selectedTiles = Object.assign({}, this.state.selectedTiles);
            let selectedGobjectsAndRegion = JSON.parse(JSON.stringify(this.state.selectedGobjectsAndRegion));

            if (selectedGobjectsAndRegion.length  === 0 ||
                selectedGobjectsAndRegion.filter(item => item.region === 'Россия').length === selectedGobjectsAndRegion.length) {
                selectedTiles.russia_conic_projection.disabled = false;
            } else {
                selectedTiles.russia_conic_projection.disabled = true;
                if (selectedTiles.russia_conic_projection.checked) {
                    selectedTiles.russia_conic_projection.checked = false;
                    selectedTiles.relief.checked = true;
                }
            }

            selectedTiles.admin.disabled = selectedTiles.russia_conic_projection.checked;

            this.setState((state) => {
                return {
                    selectedTiles: selectedTiles
                }
            });
        }

    }
    handleDoneFiltering() {
        this.setState({
            displayFilter: false,
            displayQuestion: true,
            displayResult: false,
        });
        let url = `https://${process.env.REACT_APP_DOMAIN_API}/gobjects`;
        let params = new URLSearchParams();
        Object.keys(this.state.selectedGobjects).forEach(id => params.append('id', id));
        fetch(`${url}?${params.toString()}`)
            .then(response => response.json())
            .then(gobjects => {
                this.gobjects = gobjects;
                this.setState({
                    loadedGobjectsData: true,
                })
            });
    }
    handleRetake() {
        this.setState({
            displayFilter: false,
            displayQuestion: true,
            displayResult: false,
        });
        this.timer = {};
    }
    handleMistakesOnly(gobjects) {
        this.timer = {};
        this.gobjects = shuffle(gobjects);
        this.setState({
            displayFilter: false,
            displayQuestion: true,
            displayResult: false,
        });
    }
    handleReset() {
        this.setState({
            loadedGobjectsData: false,
            displayFilter: true,
            displayQuestion: false,
            displayResult: false,
            selectedCategories: {},
            selectedGobjects: {},
            selectedTiles: {
                relief: {
                    checked: true,
                    disabled: false,
                },
                maps_for_free: {
                    checked: false,
                    disabled: false,
                },
                staen_terrain: {
                    checked: false,
                    disabled: false,
                },
                deane_kensok: {
                    checked: false,
                    disabled: false,
                },
                russia_conic_projection: {
                    checked: false,
                    disabled: false,
                },
                water: {
                    checked: true,
                    disabled: false,
                },
                admin: {
                    checked: false,
                    disabled: false,
                },
            },
            region: REGION_DEFAULT,
            category: CATEGORY_DEFAULT,
            selectedGobjectsAndRegion: []
        });
        this.timer = {};
        this.gobjects = [];
        this.attempts = [];
    }
    handleDoneAnswering(attempts, timerValues) {
        this.attempts = attempts;
        this.timer = timerValues;
        this.setState({
            displayFilter: false,
            displayQuestion: false,
            displayResult: true,
        })
    }
    handleRegionChange(region) {
        // @todo: check if region exists in filter
        this.setState({
            region: region,
            category: CATEGORY_DEFAULT
        });
    }
    handleCategoryClick(category) {
        this.setState({
            category: category
        });
    }
    handleCategoryPick(category) {
        this.handleChangeFilter()
        this.setState((state) => {
            let selectedGobjects = Object.assign({}, state.selectedGobjects);
            let selectedGobjectsAndRegion = JSON.parse(JSON.stringify(state.selectedGobjectsAndRegion));
            for (const [id, name] of Object.entries(state.filter[state.region][category])) {
                selectedGobjects[id] = name;
                selectedGobjectsAndRegion.push({
                    'id': id,
                    'name': name,
                    'region': state.region
                });
            }

            let selectedCategories = Object.assign({}, state.selectedCategories);
            selectedCategories[state.region] = selectedCategories[state.region] || {};
            selectedCategories[state.region][category] = true;

            return {
                selectedGobjects: selectedGobjects,
                selectedCategories: selectedCategories,
                selectedGobjectsAndRegion: selectedGobjectsAndRegion
            }
        });
    }
    handleCategoryUnpick(category) {
        this.handleChangeFilter()
        this.setState((state) => {
            let selectedGobjects = Object.assign({}, state.selectedGobjects);
            let selectedGobjectsAndRegion = JSON.parse(JSON.stringify(state.selectedGobjectsAndRegion));
            for (const id in state.filter[state.region][category]) {
                delete selectedGobjects[id];
                selectedGobjectsAndRegion = selectedGobjectsAndRegion.filter(function (item) { return item.id !== id });
            }

            let selectedCategories = Object.assign({}, state.selectedCategories);
            selectedCategories[state.region] = selectedCategories[state.region] || {};
            selectedCategories[state.region][category] = false;

            return {
                selectedGobjects: selectedGobjects,
                selectedCategories: selectedCategories,
                selectedGobjectsAndRegion: selectedGobjectsAndRegion
            }
        });
    }
    handleGobjectPick(id, name, category, region) {
        this.handleChangeFilter()
        this.setState((state) => {
            let selectedGobjects = Object.assign({}, state.selectedGobjects);
            selectedGobjects[id] = name;

            let selectedGobjectsAndRegion = JSON.parse(JSON.stringify(state.selectedGobjectsAndRegion));
            selectedGobjectsAndRegion.push({
                'id': id,
                'name': name,
                'region': region
            });

            // если все объекты в категории отмечены галочкой, то отмечаем категорию галочкой
            let allGogjectsInCategoryChecked = true;
            let selectedCategories = Object.assign({}, state.selectedCategories);
            if (Object.keys(this.state.filter[region][category]).length) {
                for (const id in this.state.filter[region][category]) {
                    if (id in selectedGobjects === false) {
                        allGogjectsInCategoryChecked = false;
                        break;
                    }
                }
                if (allGogjectsInCategoryChecked) {
                    selectedCategories[region] = selectedCategories[region] || {};
                    selectedCategories[region][category] = true;
                }
            }

            return {
                selectedGobjects: selectedGobjects,
                selectedCategories: selectedCategories,
                selectedGobjectsAndRegion: selectedGobjectsAndRegion
            }
        });
    }
    handleGobjectUnpick(id, category, region) {
        this.handleChangeFilter()
        this.setState((state) => {
            let selectedGobjects = Object.assign({}, state.selectedGobjects);
            delete selectedGobjects[id];

            let selectedGobjectsAndRegion = JSON.parse(JSON.stringify(state.selectedGobjectsAndRegion));
            selectedGobjectsAndRegion = selectedGobjectsAndRegion.filter(function (item) { return item.id !== id });
            
            let selectedCategories = Object.assign({}, state.selectedCategories);
            selectedCategories[region] = selectedCategories[region] || {};
            selectedCategories[region][category] = false;

            return {
                selectedGobjects: selectedGobjects,
                selectedCategories: selectedCategories,
                selectedGobjectsAndRegion: selectedGobjectsAndRegion
            }
        });
    }
    handleMapLayerPick(name) {
        this.handleChangeFilter()
        this.setState((state) => {
            let selectedTiles = state.selectedTiles;
            selectedTiles[name].checked = true;
            selectedTiles.admin.disabled = selectedTiles.russia_conic_projection.checked;
            
            return {
                selectedTiles: selectedTiles
            }
        });
    }
    handleMapLayerUnpick(name) {
        this.handleChangeFilter()
        this.setState((state) => {
            let selectedTiles = state.selectedTiles;
            selectedTiles[name].checked = false;
            selectedTiles.admin.disabled = selectedTiles.russia_conic_projection.checked;
            return {
                selectedTiles: selectedTiles
            }
        });
    }
    handleCreateTest() {
        // @todo: lock button
        // @todo: move to a module
        async function saveTest(test) {
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/tests`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(test)
            });
            // @todo: error handling
            // return await response.json();
            return response;
        }
        const newTestData = {
            id: nanoid(7),
            gobjects: Object.keys(this.state.selectedGobjects),
            layers: Object.keys(this.state.selectedTiles).filter(name => this.state.selectedTiles[name].checked && !this.state.selectedTiles[name].disabled),
        }
        this.setState({
            url: new Test(newTestData.id)
        });
        saveTest(newTestData)
            .then(test => {
                // this.setState({
                //     url: new Test(newTestData.id)
                // });
            }).catch(err => {
                // todo: collision? timeout? - retry with a new id
            });
    }

    async handleGenerateFastLink () {
        const tiles = Object.keys(this.state.selectedTiles).filter(name => this.state.selectedTiles[name].checked && !this.state.selectedTiles[name].disabled).join();
        const ids = Object.keys(this.state.selectedGobjects).join();

        this.setState((state) => {
            return {
                buttonShortUrl: {
                    text: 'Подождите...',
                    isDisabled: true
                }
            }
        });
        let response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/quicktest`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: 1,
                objects: ids,
                tiles: tiles,
            })
        });

        let result = await response.json();

        this.setState((state) => {
            return {
                shortUrl: `https://${process.env.REACT_APP_DOMAIN_WWW}/quick_test/` + result.id,
                isShowShortUrl: true,
                buttonShortUrl: {
                    text: 'Сгенерировать ссылку',
                    isDisabled: false
                },
            }
        });
    }

    handleChangeFilter = () => {
        this.setState((state) => {
            return {
                isShowShortUrl: false,
                shortUrl: '',
                buttonShortUrl: {
                    text: 'Сгенерировать ссылку',
                    isDisabled: false
                },
            }
        });
    }

    render() {
        return (
            <Box>
                { this.state.displayFilter && (
                    <Filter
                        filter={this.state.filter ?? {}}
                        regions={this.regions}
                        region={this.state.region}
                        onRegionChange={this.handleRegionChange}
                        categories={this.categories.filter(category => this.state.filter[this.state.region].hasOwnProperty(category))}
                        category={this.state.category}
                        gobjects={(this.state.region && this.state.category) ? this.state.filter[this.state.region][this.state.category] ?? {} : {}}
                        onCategoryClick={this.handleCategoryClick}
                        onCategoryPick={this.handleCategoryPick}
                        onCategoryUnpick={this.handleCategoryUnpick}
                        selectedGobjects={this.state.selectedGobjects}
                        selectedCategories={this.state.selectedCategories}
                        onObjectPick={this.handleGobjectPick}
                        onObjectUnpick={this.handleGobjectUnpick}
                        onDoneFiltering={this.handleDoneFiltering}
                        selectedTiles={this.state.selectedTiles}
                        tileLabels={this.tileLabels}
                        tileReliefLabels={this.tileReliefLabels}
                        onMapLayerPick={this.handleMapLayerPick}
                        onMapLayerUnpick={this.handleMapLayerUnpick}
                        onCreateTest={this.handleCreateTest}
                        url={this.state.url}
                        isShowShortUrl={this.state.isShowShortUrl}
                        generateFastLink={this.handleGenerateFastLink}
                        shortUrl={this.state.shortUrl}
                        buttonShortUrl={this.state.buttonShortUrl}
                    />
                )
                }
                { this.state.displayQuestion && (
                    this.state.loadedGobjectsData ?
                    <World
                        tileData={this.tileData.filter(t => this.state.selectedTiles[t.name].checked && !this.state.selectedTiles[t.name].disabled )}
                        gobjects={this.gobjects}
                        onFinish={this.handleDoneAnswering}
                        IsConicProjection={ this.state.selectedTiles.russia_conic_projection.checked }
                    />
                    :
                    <Skeleton variant="rect" width={'100vw'} height={'100vh'} />
                )}
                { this.state.displayResult && (
                    <Result
                        gobjects={this.gobjects}
                        attempts={this.attempts}
                        timer={this.timer}
                        onReset={this.handleReset}
                        onRetake={this.handleRetake}
                        onMistakes={this.handleMistakesOnly}
                    />
                )}
            </Box>
        );
    }
}

export default ModeEducation;
