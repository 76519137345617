
export default class SoundEffect {
    constructor(url) {
        this.url = url;
        this.audio = null;
    }
    play() {
        // по какой-то причине в Safary звук играется всего один раз
        // поэтому сделал временную заплатку, потом, может быть, разберусь в чем дело
        delete this.audio;
        this.audio = new Audio(this.url);
        this.audio.play();
    }
}
