
import React, {useEffect, useState} from 'react';

import { useParams, useHistory } from "react-router-dom";
import URLSafeBase64 from "urlsafe-base64";

import Box from '@material-ui/core/Box';
import World from "./World/World";
import { layers } from "./World/tiles";
import Result from "./Result/Result";
import Skeleton from '@material-ui/lab/Skeleton';
import shuffle from 'shuffle-array';

export default function ModeCustomFilter() {
    const { lcode, gcode } = useParams();
    const lnames = URLSafeBase64.decode(lcode).toString().split(',');
    const tileLayers = layers.filter(layer => lnames.indexOf(layer.name) !== -1);

    const [questions, setQuestions] = useState({
        isLoaded: false,
        isError: false,
        gobjects: [],
    });

    const [results, setResults] = useState({
        displayResults: false,
        attempts: [],
        timer: {},
    });

    const history = useHistory();

    useEffect(() => {
        const url = `https://${process.env.REACT_APP_DOMAIN_API}/gobjects`;
        const params = new URLSearchParams();
        const gids = URLSafeBase64.decode(gcode).toString().split(',');
        gids.forEach(id => params.append('id', id));
        fetch(`${url}?${params.toString()}`)
            .then(response => response.json())
            .then((gobjects) => {
                setQuestions({
                    isLoaded: true,
                    isError: false,
                    gobjects: shuffle(gobjects),
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                setQuestions({
                    isLoaded: false,
                    isError: true,
                    gobjects: [],
                });
            });
    }, [gcode]);

    function handleDoneAnswering(attempts, timerValues) {
        setResults({
            displayResults: true,
            attempts: attempts,
            timer: {
                minutes: timerValues.minutes,
                seconds: timerValues.seconds,
            }
        });
    }

    function handleReset() {
        history.push('/');
    }

    function handleRetake() {
        setQuestions({
            isLoaded: true,
            isError: false,
            gobjects: shuffle(questions.gobjects),
        });
        setResults({
            displayResults: false,
            attempts: [],
            timer: {},
        });
    }

    function handleMistakesOnly(gobjects) {
        setQuestions({
            isLoaded: true,
            isError: false, // @todo: обработка ошибок
            gobjects: shuffle(gobjects),
        });
        setResults({
            displayResults: false,
            attempts: [],
            timer: {},
        });
    }

    return (
        <Box>
            {! results.displayResults && (
                questions.isLoaded ?
                <World
                    tileData={tileLayers}
                    gobjects={questions.gobjects}
                    onFinish={handleDoneAnswering}
                />
                :
                <Skeleton variant="rect" width={'100vw'} height={'100vh'} />
            )}
            {results.displayResults && (
                <Result
                    gobjects={questions.gobjects}
                    attempts={results.attempts}
                    timer={results.timer}
                    onReset={handleReset}
                    onRetake={handleRetake}
                    onMistakes={handleMistakesOnly}
                />
            )}
        </Box>
    );
}