import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Skeleton} from "@material-ui/lab";

export default function SelectObjects(props) {
    function handleObjectChange(id, name, e) {
        if (e.target.checked) {
            props.onObjectPick(id, name, props.category, props.region);
        } else {
            props.onObjectUnpick(id, props.category, props.region);
        }
    }

    let gobjects;
    if (props.gobjects && Object.keys(props.gobjects).length) {
        gobjects = Object.entries(props.gobjects).map(([id, name]) => (
            <FormControlLabel
                key={id}
                control={<Checkbox onChange={(e) => {
                    handleObjectChange(id, name, e)
                }}/>}
                label={name}
                checked={id in props.selectedGobjects}
            />
        ));
    } else {
        gobjects = [1, 2, 3, 4, 5, 6].map((key) => (
            <Skeleton key={key}>
                <FormControlLabel
                    control={<Checkbox/>}
                    label="Аравийский полуостров"
                />
            </Skeleton>
        ))
    }

    return (
        <FormControl component="fieldset">
            {gobjects}
        </FormControl>
    )
}
