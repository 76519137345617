import {Category} from "../Entity/Category";
import {CategoryCollection} from "../Collection/CategoryCollection";
import {getFilterCategoriesUrl, getAllCategoriesUrl} from "../Service/EndpointFilterService";

export class CategoryRepository {
    /**
     * @param {String} regionName
     * @param {Boolean} fetchFromCacheFirst - сначала пытается взять данные из кеша(хардкод), а если в кеше пусто, то вызывает API
     * @returns {Promise}
     */
    getAllByRegion(regionName, fetchFromCacheFirst = false) {
        return fetch(getFilterCategoriesUrl(regionName, fetchFromCacheFirst))
            .then(response => response.json())
            .then(categoriesData => categoriesData.sort((a, b) => (a.num > b.num) ? 1 : ((b.num > a.num) ? -1 : 0)).map(categoriesData => new Category(categoriesData.category)))
            .then(categories => new CategoryCollection(categories));
    }

    getAll(fetchFromCacheFirst = false) {
        return fetch(getAllCategoriesUrl(fetchFromCacheFirst))
            .then(response => response.json())
            .then(categoriesData => categoriesData.sort((a, b) => (a.num > b.num) ? 1 : ((b.num > a.num) ? -1 : 0)).map(categoriesData => new Category(categoriesData.category)))
            .then(categories => new CategoryCollection(categories));
    }
}
