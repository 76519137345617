import {Gobject} from "../Entity/Gobject";
import {GobjectCollection} from "../Collection/GobjectCollection";
import {getFilterGobjectsUrl} from "../Service/EndpointFilterService";

export class GobjectRepository {
    /**
     * @param {String} regionName
     * @param {String} categoryName
     * @param {Boolean} fetchFromCacheFirst - сначала пытается взять данные из кеша(хардкод), а если в кеше пусто, то вызывает API
     * @returns {Promise}
     */
    getAllByRegionAndCategory(regionName, categoryName, fetchFromCacheFirst = false) {
        return fetch(getFilterGobjectsUrl(regionName, categoryName, fetchFromCacheFirst))
            .then(response => response.json())
            .then(gobjectsData => gobjectsData.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(gobjectData => new Gobject(gobjectData.id, gobjectData.name)))
            .then(gobjects => new GobjectCollection(gobjects));
    }
}
