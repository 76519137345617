import {REGION_DEFAULT} from "../Entity/Region";

export class RegionCollection {
    /**
     * @param {Array} data
     */
    constructor(data = []) {
        this.regions = new Map();

        data.forEach(region => this.regions.set(region.name, region));
    }

    /**
     * @param {String} regionName
     */
    getRegion(regionName) {
        return this.regions.get(regionName);
    }

    getDefaultRegion() {
        return this.regions.get(REGION_DEFAULT);
    }

    getNames() {
        return Array.from(this.regions, ([name, region]) => name);
    }
}
