
const regions = new Map();
regions.set("Австралия и Океания", 1);
regions.set("Азия", 2);
regions.set("Антарктида", 3);
regions.set("Африка", 4);
regions.set("Европа", 5);
regions.set("Россия", 6);
regions.set("Северная Америка", 7);
regions.set("Южная Америка", 8);
regions.set("Ростовская область", 9);
regions.set("Башкирия", 10);
regions.set("Беларусь", 11);
regions.set("Казахстан", 12);

const categories = new Map();
categories.set("Моря", 1);
categories.set("Заливы", 2);
categories.set("Острова", 3);
categories.set("Проливы", 4);
categories.set("Полуострова", 5);
categories.set("Мысы", 6);
categories.set("Равнины", 7);
categories.set("Возвышенности", 8);
categories.set("Горы", 9);
categories.set("Пустыни", 10);
categories.set("Реки", 11);
categories.set("Озёра", 12);
categories.set("Каналы", 13);
categories.set("Водопады", 14);
categories.set("Страны", 15);
categories.set("Территориальное деление", 16);
categories.set("Столицы", 17);
categories.set("Адм. центры", 18);
categories.set("Водохранилища", 19);
categories.set("Природные зоны", 20);
categories.set("Муниципальные образования", 21);
categories.set("Почвы", 22);
categories.set("ООПТ", 24);
categories.set("Полезные ископаемые", 25);
categories.set("Путешествия", 26);
categories.set("История", 27);

export const getAllCategoriesUrl = (cached = false) => {
    if (cached) {
        return `https://${process.env.REACT_APP_DOMAIN_WWW}/filter/20220129/ru/categories.json`;
    } else {
        return `https://${process.env.REACT_APP_DOMAIN_API}/categories`;
    }
}

export const getFilterCategoriesUrl = (regionName, cached = false) => {
    // удалю всю сложность, когда перейду от имен к идентификаторам регионов и нормализую БД
    if (cached) {
        return `https://${process.env.REACT_APP_DOMAIN_WWW}/filter/20220129/ru/categories/${regions.get(regionName)}.json`;
    } else {
        const params = new URLSearchParams();
        params.append('region', regionName);

        return `https://${process.env.REACT_APP_DOMAIN_API}/categories?${params.toString()}`;
    }
};

export const getAllRegionsUrl = (cached = false) => {
    if (cached) {
        return `https://${process.env.REACT_APP_DOMAIN_WWW}/filter/20220129/ru/regions.json`;
    } else {
        return `https://${process.env.REACT_APP_DOMAIN_API}/regions`;
    }
};

export const getFilterGobjectsUrl = (regionName, categoryName, cached = false) => {
    if (cached) {
        return `https://${process.env.REACT_APP_DOMAIN_WWW}/filter/20220129/ru/categories/${regions.get(regionName)}/${categories.get(categoryName)}.json`;
    } else {
        const params = new URLSearchParams();
        params.append('region', regionName);
        params.append('category', categoryName);

        return `https://${process.env.REACT_APP_DOMAIN_API}/gobjects?${params.toString()}`;
    }
};
