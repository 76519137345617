import {CATEGORY_DEFAULT} from "../Entity/Category";
import {REGION_DEFAULT} from "../Entity/Region";

export class FilterService {
    /**
     *
     * @param {RegionRepository} regionsRepository
     * @param {CategoryRepository} categoryRepository
     * @param {GobjectRepository} gobjectsRepository
     */
    constructor(regionsRepository, categoryRepository, gobjectsRepository) {
        this.regionsRepository = regionsRepository;
        this.categoryRepository = categoryRepository;
        this.gobjectsRepository = gobjectsRepository;
    }

    /**
     *
     * @return {Promise<T>}
     */
    fetchRegionCollectionInitial() {
        return new Promise((resolve, reject) => {
            const gobjectsPromise = this.gobjectsRepository.getAllByRegionAndCategory(REGION_DEFAULT, CATEGORY_DEFAULT, true);
            const categoriesPromise = this.categoryRepository.getAllByRegion(REGION_DEFAULT, true);
            const regionsPromise = this.regionsRepository.getAll(true);

            Promise.all([gobjectsPromise, categoriesPromise, regionsPromise]).then(([gobjectCollection, categoryCollection, regionCollection]) => {
                const seas = categoryCollection.getCategory(CATEGORY_DEFAULT);
                if (seas) {
                    seas.setGobjects(gobjectCollection);
                }
                const africa = regionCollection.getRegion(REGION_DEFAULT);
                if (africa) {
                    africa.setCategories(categoryCollection);
                }

                resolve(regionCollection);
            }).catch(reject);
        });
    }

    /**
     *
     * @return {Promise<T>}
     */
    fetchCategoryCollection(regionName) {
        return new Promise((resolve, reject) => {
            const categoriesPromise = this.categoryRepository.getAllByRegion(regionName, true);
            categoriesPromise
                .then(categoryCollection => {
                    resolve(categoryCollection);
                }).catch(reject);
        });
    }

    fetchGobjectCollection(regionName, categoryName) {
        return new Promise((resolve, reject) => {
            const gobjectsPromise = this.gobjectsRepository.getAllByRegionAndCategory(regionName, categoryName, true);
            gobjectsPromise.then(gobjectCollection => {
                resolve(gobjectCollection);
            }).catch(reject);
        });
    }

    fetchAllCategories() {
        return new Promise((resolve, reject) => {
            const categoriesPromise = this.categoryRepository.getAll(true);
            categoriesPromise
                .then(categoryCollection => {
                    resolve(categoryCollection);
                }).catch(reject);
        });
    }
}