import React, {
    useEffect,
    useState
} from 'react';
import {
    useHistory,
    useParams
} from "react-router-dom";

import Box from '@material-ui/core/Box';
import World from "./World/World";
import { layers } from "./World/tiles";
import Result from "./Result/Result";
import Skeleton from '@material-ui/lab/Skeleton';
import shuffle from 'shuffle-array';

export default function ModeQuickTest() {
    const { id } = useParams();
    const [questions, setQuestions] = useState({
        isLoaded: false,
        isError: false,
        gobjects: [],
    });

    const [tileLayers, setTileLayers] = useState({

    });

    const [results, setResults] = useState({
        displayResults: false,
        attempts: [],
        timer: {},
    });

    const history = useHistory();

    useEffect(() => {
        const url = `https://${process.env.REACT_APP_DOMAIN_API}/quicktest`;
        const params = new URLSearchParams();
        params.append('id', id);
        fetch(`${url}?${params.toString()}`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //handle errors in the way you want to
                    switch (response.status) {
                        case 404:
                        console.log('Object not found');
                        break;
                        case 500:
                        console.log('Internal server error');
                        break;
                        default:
                        console.log('Some error occured');
                        break;
                    }
                    setQuestions({
                        isLoaded: false,
                        isError: true,
                        gobjects: [],
                    });
                    return response.then(Promise.reject.bind(Promise));
                }
            })
            .then(({data}) => {
                let lnames = data.tiles.split(',');
                const tileLayers = layers.filter(layer => lnames.indexOf(layer.name) !== -1)
                setTileLayers(tileLayers);
                setQuestions({
                    isLoaded: true,
                    isError: false,
                    gobjects: shuffle(data.gobjects),
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                setQuestions({
                    isLoaded: false,
                    isError: true,
                    gobjects: [],
                });
            });
    }, [id]);

    function handleDoneAnswering(attempts, timerValues) {
        setResults({
            displayResults: true,
            attempts: attempts,
            timer: {
                minutes: timerValues.minutes,
                seconds: timerValues.seconds,
            }
        });
    }

    function handleReset() {
        history.push('/');
    }

    function handleRetake() {
        setQuestions({
            isLoaded: true,
            isError: false,
            gobjects: shuffle(questions.gobjects),
        });
        setResults({
            displayResults: false,
            attempts: [],
            timer: {},
        });
    }

    function handleMistakesOnly(gobjects) {
        setQuestions({
            isLoaded: true,
            isError: false, // @todo: обработка ошибок
            gobjects: shuffle(gobjects),
        });
        setResults({
            displayResults: false,
            attempts: [],
            timer: {},
        });
    }

    return (
        <Box>
            {! results.displayResults && (
                questions.isLoaded ?
                <World
                    tileData={tileLayers}
                    gobjects={questions.gobjects}
                    onFinish={handleDoneAnswering}
                    IsConicProjection={ tileLayers.filter(layer => layer.name === 'russia_conic_projection').length > 0 }
                />
                :
                <Skeleton variant="rect" width={'100vw'} height={'100vh'} />
            )}
            {results.displayResults && (
                <Result
                    gobjects={questions.gobjects}
                    attempts={results.attempts}
                    timer={results.timer}
                    onReset={handleReset}
                    onRetake={handleRetake}
                    onMistakes={handleMistakesOnly}
                />
            )}
        </Box>
    );
}
