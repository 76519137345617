
import React from 'react';
import Chip from '@material-ui/core/Chip';

export default function Selected(props) {
    return (
        <React.Fragment>
            {Object.entries(props.selected ?? {}).map(([id, name]) => (
                <Chip
                    key={id}
                    label={name}
                    onDelete={(e) => {props.onObjectUnpick(id)}}
                />
            ))}
        </React.Fragment>
    )
}