import React from 'react';

import ListItemText from '@material-ui/core/ListItemText';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import {Skeleton} from "@material-ui/lab";

export default function SelectCategory(props) {
    function handleCategoryClick(category) {
        if (category !== props.category) {
            props.onCategoryClick(category);
        }
    }

    function handleCategoryChange(category, e) {
        if (e.target.checked) {
            props.onCategoryPick(category);
        } else {
            props.onCategoryUnpick(category);
        }
    }

    function checked(category) {
        // приводим к bool, иначе react ругается про controlled components
        return !! props.selectedCategories[props.region]?.[category];
    }

    let categories;
    if (props.categories && props.categories.length) {
        categories = props.categories.map((name) => (
            <MenuItem
                key={`${props.region}${name}`}
                role={undefined}
                button
                selected={props.category === name}
                onClick={(e) => {
                    handleCategoryClick(name)
                }}
            >
                <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{'aria-labelledby': `${props.region}${name}`}}
                    onChange={(e) => {
                        handleCategoryChange(name, e)
                    }}
                    checked={checked(name)}
                />
                <ListItemText id={`${props.region}${name}`} primary={name}/>
            </MenuItem>
        ))
    } else {
        categories = [1, 2, 3, 4, 5, 6].map((key) => (
            <Skeleton key={key}>
                <MenuItem button>
                    <Checkbox/>
                    <ListItemText primary="Возвышенности"/>
                </MenuItem>
            </Skeleton>
        ));
    }

    return (
        <MenuList>
            {categories}
        </MenuList>
    )
}
