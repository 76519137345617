import {GobjectCollection} from "../Collection/GobjectCollection";

export const CATEGORY_DEFAULT = 'Моря';

export class Category {
    /**
     *
     * @param {String} name
     * @param {GobjectCollection|null} collection
     */
    constructor(name, collection = null) {
        this.name = name;

        this.gobjects = collection ?? new GobjectCollection();
    }

    /**
     *
     * @param {CategoriesCollection} collection
     */
    setGobjects(collection) {
        this.gobjects = collection;
    }

    /**
     * @return {GobjectCollection|null}
     */
    getGobjects() {
        return this.gobjects;
    }
}
