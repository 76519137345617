

import React from 'react';
import {latLngBounds,latLng, marker, icon, bounds, point, CRS} from 'leaflet';
import Proj from 'proj4leaflet';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import {Timer} from "easytimer.js";

import Question from './Question';
import './../Leaflet/layer/Question.css';
import Progress from './Progress';
import './../Leaflet/control/Progress.Control.css';
import {Hint} from './../Leaflet/layer/Hint';
import './../Leaflet/layer/Hint.css';
import SoundEffect from './SoundEffect';
import {getStyle, style, getStyleOut, getStyleOver, getStyleHint} from './Style';
import {NO_ANSWER} from '../UserAnswer';
import { ConfigurationModal } from "../ConfigurationModal/ConfigurationModal";
import { OnBoarding } from "../OnBoarding/OnBoarding";
// import { ContactSupportOutlined } from '@material-ui/icons';

class World extends React.Component {

    constructor(props) {
        super(props);
        this.curGobjIndex = 0;
        this.clicksCounters = [];
        this.layers = [];
        this.clickpath = new Set();
        this.timer = new Timer();
        this.map = null;
        this.effects = {
            right: new SoundEffect('/audio/effects/right.mp3'),
            wrong: new SoundEffect('/audio/effects/wrong.mp3'),
        };
        this.handleWhenCreated = this.handleWhenCreated.bind(this);
        this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
        this.defaultZoom = 3;
        this.riverWeight = this.isMobile ? 7 : 5;
        this.colorRiver = '#191acb';
        this.config = null;
        this.writeHeight = false;
        this.state = {
            lakesJsonLoadingStarted: false,
            lakesJsonLoaded: false,
            lakesJsonData: [],
            riversJsonLoadingStarted: false,
            riversJsonLoaded: false,
            riversJsonData: [],
        }
    };
    componentDidMount() {
        // если открыть карту в Яндекс Браузере
        // то в некоторых случаях карта как-то смещается верх и кнопки приближения и удаление не доступны
        // чтобы этого не происходило, не придумал ничего лучше, чем добавить скролл
        this.handleChangeDisplayHelp = this.handleChangeDisplayHelp.bind(this)
        window.scrollTo(0, 0);
        this.hint = new Hint();
        this.setState(prev => ({
            ...prev,
            helpDisplay: window.innerWidth > 600 ? "desktop" : "mobile",
        }))
        window.addEventListener("resize", this.handleChangeDisplayHelp)
        window.addEventListener("resize", this.handleChangeResize)
    }

    componentDidUpdate() {
        if (!this.writeHeight) {
            this.handleChangeResize()
            this.writeHeight = true;
        }
    }

    handleChangeResize () {
        document.querySelector(".leaflet-container").style.height = `${window.innerHeight}px`
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleChangeDisplayHelp)
        window.removeEventListener("resize", this.handleChangeResize)
    }

    handleChangeDisplayHelp () {
        if ((this.state.helpDisplay === "desktop" && window.innerWidth <= 600) || (this.state.helpDisplay === "mobile" && window.innerWidth > 600)) {
            this.setState(prev => ({
                ...prev,
                helpDisplay: prev.helpDisplay === "desktop" ? "mobile" : "desktop",
            }))
        }
    }

    startTimer = () => {
        this.timer.start();
        this.timer.addEventListener('secondsUpdated', () => {
            this.map?.fire('tick', {
                time: this.timer.getTimeValues().toString(['minutes', 'seconds']),
            });
        });
    }
    handleWhenCreated(map) {
        this.map = map;

        // на карте есть кнопка "не знаю", при нажатии этой кнопки нужно переходить к следующему вопросу
        // этот обработчик как раз и предназначен для этого
        map.on('noanswer', function () {
            this.noAnswer(map);
        }, this);

        // на карте есть кнопка "завершить"
        map.on('quit', function () {
            do {
                this.processSkipAnswer(this.curGobjIndex);
                // this.nextQuestion(map);
                map.fire('question', {
                    question: '',
                    progress: this.getProgress(this.curGobjIndex, this.props.gobjects),
                });
            } while(!this.lastQuestion());
            this.quit();
        }, this);

        map.on('zoomend', function (e) {
            const currentZoom = map.getZoom();
            this.layers.forEach((layer, index) => {
                if (layer?.options?.attribution.indexOf('рек') !== -1) {
                    this.layers[index].setStyle({
                        ...layer?.options,
                        weight: ((this.defaultZoom * this.riverWeight) / currentZoom),
                    });
                }
            });
        }, this);
    }
    // вот это все от сюда и до answer() нужно инкапсулировать отдельно от компонента World
    nextQuestion(map) {
        setTimeout(() => {
            map.fire('question', {
                question: this.props.gobjects[this.curGobjIndex].name,
                progress: this.getProgress(this.curGobjIndex, this.props.gobjects),
            });
        }, 600);
    }
    quit() {
        this.timer.pause();
        this.curGobjIndex = 0;
        setTimeout(() => {
            this.props.onFinish(this.clicksCounters, this.timer.getTimeValues(), this.props.gobjects);
        }, 1800);
    }
    lastQuestion() {
        return this.curGobjIndex >= this.layers.length
    }
    correctAnswer(index) {
        return index === this.curGobjIndex;
    }
    processCorrectAnswer(index) {
        if (this?.config?.sound) {
            this.effects.right.play();
        }
        this.clicksCounters[index] = this.clicksCounters[index] + 1 || 1;
        this.layers[index].setStyle(getStyle(this.clicksCounters[index]));
        this.curGobjIndex++;
        this.layers[index].off();
        this.layers[index].bringToBack(); // это полезно, когда два объекта накладываются друг на друга
        // исправляет ошибку с повторными кликами на маркерах
        this.clickpath.clear();
    }
    processIncorrectAnswer(index, latlng, map) {
        if (this?.config?.sound) {
            this.effects.wrong.play();
        }
        if (latlng) {
            this.hint
                .setLatLng(latlng)
                .setContent(this.props.gobjects[index].name)
                .openOn(map);
        }
        this.clicksCounters[this.curGobjIndex] = this.clicksCounters[this.curGobjIndex] + 1 || 1;
        if (this.clicksCounters[this.curGobjIndex] >= 3) {
            this.layers[this.curGobjIndex].setStyle(getStyleHint());
            map.fitBounds(this.layers[this.curGobjIndex].getBounds(), {
                maxZoom: 8,
            });
        }
    }
    processSkipAnswer(index)
    {
        this.clicksCounters[index] = NO_ANSWER;
        this.layers[index].setStyle(getStyle(this.clicksCounters[index]));
        this.curGobjIndex++;
        this.layers[index].off();
        this.layers[index].bringToBack(); // это полезно, когда два объекта накладываются друг на друга
        // исправляет ошибку с повторными кликами на маркерах
        this.clickpath.clear();
    }
    // обработчик ответа пользователя
    answer(index, latlng, map) {
        if (this.correctAnswer(index)) {
            this.processCorrectAnswer(index)
            if (this.lastQuestion()) {
                this.quit();
            } else {
                this.nextQuestion(map);
            }
        } else {
            this.processIncorrectAnswer(index, latlng, map);
        }
    }
    // обработчик, когда пользователь не знает ответ и не хочет или не может искать объект на карте
    // засчитываем как неправильный ответ
    noAnswer(map) {
        // нужен ли звук?
        this.clicksCounters[this.curGobjIndex] = 3;
        this.processIncorrectAnswer(this.curGobjIndex, null, map);
    }
    getBounds() {
        return latLngBounds(
            latLng([-85.0511287798, -260]),
            latLng([85.0511287798, 260])
        );
    }
    getProgress(index, questions) {
        return `${index + 1}/${questions.length}`;
    }
    injectCustomIcon(feature, latlng) {
        const getMarkerOptions = (feature) => {
            // feature.iconOptions = {
            //     iconUrl: 'http://localhost:3000/icon/map/mountain/1.png',
            //     iconSize:     [25, 38], // size of the icon
            //     iconAnchor:   [13, 38], // point of the icon which will correspond to marker's location
            //     popupAnchor:  [-13, -38] // point from which the popup should open relative to the iconAnchor
            // };
            if (typeof feature.iconOptions !== 'undefined') {
                return {
                    icon: icon(feature.iconOptions)
                }
            }

            return {};
        }
        return marker(latlng, getMarkerOptions(feature));
    }
    getStyleForGeoJson(gobjectName) {
        const styleRes = { ...style };
        if (gobjectName.indexOf('рек') !== -1) {
            styleRes.weight = this.riverWeight;
            styleRes.color = this.colorRiver;
        }
        return styleRes;
    }

    getStyleForGeoJsonLayer() {
        const styleRes = { ...style };
        styleRes.weight = 0.6;
        styleRes.color = this.colorRiver;        
        return styleRes;
    }

    onStart = (config) => {
        this.setState({ config })
        this.config = config;
        if (!config.onboarding) {
            this.startTimer();
        }
    }

    onCloseOnboarding = () => {
        this.config = {...this.config, onboarding: false};
        this.setState(prev => ({
            ...prev,
            onboarding: false,
        }));
        this.startTimer();
        // if (this.state.config.objectView) {
        //     document.querySelector(".display-help").classList.remove("hidden")
        //     setTimeout(() => {
        //         document.querySelector(".display-help").classList.add("hidden")
        //     }, 1500)
        // }
    };

    handleChangeConfig = (key) => {
        this.config[key] = !this.config[key];
        this.setState(prev => ({
            ...prev,
            config: {...this.config}
        }))
    }

    render() {
        
        if (this.props.IsConicProjection && this.props.tileData.filter(t => t.name === 'water').length > 0) {
            if (!this.state.lakesJsonLoadingStarted) {
                this.setState({
                    lakesJsonLoadingStarted: true
                });
                fetch('https://storage.yandexcloud.net/geojson/lakes.geojson', {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => response.json() )
                .then(data => {
                    this.setState({                        
                        lakesJsonLoaded: true,
                        lakesJsonData: data.features
                    })
                });
            }
            if (!this.state.riversJsonLoadingStarted) {
                this.setState({
                    riversJsonLoadingStarted: true
                });
                fetch('https://storage.yandexcloud.net/geojson/rivers.geojson', {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => response.json())
                .then(data => {              
                    this.setState({                        
                        riversJsonLoaded: true,
                        riversJsonData: data.features
                    })
                });
            }
            if (!this.state.lakesJsonLoaded || !this.state.riversJsonLoaded) {
                return(<div className='leaflet-container'></div>);
            }
        }

        let map_MaxZoom = 10;
        let map_MinZoon = 2;
        let map_CRS = CRS.EPSG3857;
        let map_Center = [42.02, 43.07];
        let map_WorldCopyJump = true;
        let map_maxBounds = this.getBounds();

        if (this.props.IsConicProjection) {
            map_MinZoon = 1;
            map_MaxZoom = 8;
            map_CRS = new Proj.CRS(
                "ESRI:102027",
                "+proj=lcc +lat_1=15 +lat_2=65 +lat_0=30 +lon_0=95 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs",
                {
                    origin: [-5052108.18200000, 6990732.58730000],
                    bounds: bounds(point(5052108, 6990732), point(-5052108, 950000)),
                    resolutions: [63287.54781585, 31643.77390792, 15821.88695396, 7910.94347698, 3955.47173849, 1977.73586925, 988.86793462, 494.43396731, 247.21698366]
                }
            );
            map_Center = [65, 90];
            map_WorldCopyJump = false;
            map_maxBounds = latLngBounds(
                map_CRS.unproject(point([-7090732, 950000])),
                map_CRS.unproject(point([9757721, 6990732]))
            );
        }

        let tiles = [];
        this.props.tileData.forEach((data) => {
            if (this.props.IsConicProjection && data.name === 'water') {
                return;
            }
            tiles.push(
                <TileLayer
                    url={data.url}
                    key={data.name}
                />
            );
        });
        let gobjects = [];
        this.props.gobjects.forEach((gobject, index) => {
            // Нам нужен какой-нибудь ключ для всех элементов одного объекта, чтобы отслеживать клики (маркер + полигон)
            if (Array.isArray(gobject.geojson.features)) {
                gobject.geojson = gobject.geojson.features.map(f => {f.key = gobject.id; return f;});
            } else {
                console.error(gobject.id);
            }
            gobjects.push(
                <GeoJSON
                    data={gobject.geojson}
                    eventHandlers={{
                        click: (e) => {
                            let click = JSON.stringify(e.latlng);
                            if (e?.sourceTarget?.feature?.key) {
                                click = e.sourceTarget.feature.key;
                            }
                            if (! this.clickpath.has(click)) {
                                this.clickpath.add(click);
                                this.answer(index, e.latlng, e.layer._map);
                            }
                        },
                        mouseover: (e) => {
                            e.target.setStyle(getStyleOver(e.target));
                        },
                        mouseout: (e) => {
                            e.target.setStyle(getStyleOut(e.target));
                        },
                        add: (e) => {
                            // сохранить ссылку, чтобы подсветить слой после трех неправильных кликов
                            this.layers[index] = e.target;
                        }
                    }}
                    pointToLayer={this.injectCustomIcon}
                    style={() => this.getStyleForGeoJson(gobject.name)}
                    key={index}
                    attribution={gobject.name}
                />
            );
        });

        let objectsCount = gobjects.length;
        this.state.lakesJsonData.forEach((gobject, index) => {
            if (gobject.geometry.coordinates.length === 0) 
                return;
            gobjects.push(
                <GeoJSON
                    data={gobject}
                    style={() => this.getStyleForGeoJsonLayer()}
                    key={'lake' + (objectsCount + index)}
                    attribution={'lake' + index}
                />
            );
        });
        objectsCount = gobjects.length;
        this.state.riversJsonData.forEach((gobject, index) => {
            if (gobject.geometry.coordinates.length === 0) 
                return;
            gobjects.push(
                <GeoJSON
                    data={gobject}
                    style={() => this.getStyleForGeoJsonLayer()}
                    key={'river' + (objectsCount + index)}
                    attribution={'river' + index}
                />
            );
        });

        const question = this.props.gobjects[this.curGobjIndex].name;
        // @todo what if empty?
        return (
            <>
                <MapContainer
                  zoom={this.defaultZoom}                  
                  attributionControl={false}
                  style={{ height: '100vh', width: '100vw', background: '#ffffff' }}
                  whenCreated={this.handleWhenCreated}
                  maxBoundsViscosity={0.9}
                  
                  minZoom={map_MinZoon}
                  maxZoom={map_MaxZoom}
                  crs={ map_CRS }
                  center={ map_Center }
                  worldCopyJump={ map_WorldCopyJump }
                  maxBounds={ map_maxBounds }
                >
                    {tiles}
                    {gobjects.reverse()}

                    {this.config && (
                      <>
                          <Progress
                            position="topright"
                            button={'Не знаю'}
                            quit={'Завершить'}
                            question={question}
                            time={'00:00'}
                            progress={this.getProgress(this.curGobjIndex, this.props.gobjects)}
                            config={this.state.config}
                            changeConfig={this.handleChangeConfig}
                          />
                          {this.config.objectView && <Question question={question} interactive={false} pane={'popupPane'} />}
                          {this.config.onboarding && (
                            <OnBoarding helpDisplay={this.state.helpDisplay} onClose={this.onCloseOnboarding}/>
                          )}
                          {/* {
                            this.config.objectView &&
                            <div className="display-help hidden">{question}</div>
                          } */}
                      </>
                    )}

                </MapContainer>

                {!this.config && <ConfigurationModal objects={this.props.gobjects} onStart={this.onStart}/>}
            </>
        )
        // .reverse() для того, чтобы более ранние полигоны накладывались на более поздние
        // без .reverse() работает для тестов, в которых объекты не перекрывают друг друга
    }
}

export default World;
