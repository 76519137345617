import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "unset",
    height: "unset",
    background: "#BDBDBD",
    borderRadius: "100px",
    boxShadow: "0px 4px 3px rgba(0, 0, 0, 0.25)",
    cursor: "pointer",
    "@media (max-width:650px)": {
      boxShadow: "inset 5px 5px 4px rgba(0, 0, 0, 0.17)",
      width: "105px",
      height: "51px",
    },
  },
  inner: {
    width: "33px",
    height: "33px",
    background: "#EAEAEA",
    borderRadius: "50%",
    display: "flex",

    alignItems: "center",
    justifyContent: "center",
    margin: (props) => (props.active ? "0 0 0 auto" : "0 auto 0 0"),
    "@media (max-width:650px)": {
      boxShadow: "-3px 3px 4px rgba(0, 0, 0, 0.15)",
      width: "50.82px",
      height: "50.82px",
    },
  },
}));

const Toggler = ({ classname, children, tooltip, active, onToggle }) => {
  const styles = useStyles({ active });

  return (
    <Box className={classname} onClick={onToggle}>
      <Box className={styles.inner}>{children}</Box>
    </Box>
  );
};

export { Toggler };
