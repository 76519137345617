import {Layer, DomUtil, Util, latLngBounds, Bounds } from 'leaflet';

export var Question = Layer.extend({
    options: {
        question: '',
    },
    initialize: function (options) {
        this._hanler = null;
        Util.setOptions(this, options);
    },
    onAdd: function (map) {
        if (! this._question) {
            this._question = DomUtil.create('div');
            DomUtil.addClass(this._question, 'leaflet-question-layer');

            this._innerQuestion = DomUtil.create('div', 'block');
            this._question.appendChild(this._innerQuestion);
        }
        this._displayQuestion(this.options.question, 1200);
        map.on('question', (data) => {
            this._displayQuestion(data.question, 1000);
        }, this);
    },
    onRemove: function () {
        DomUtil.remove(this._question);
        if (this.options.interactive) {
            this.removeInteractiveTarget(this._question);
        }
        clearTimeout(this._hanler);
    },
    _displayQuestion: function (question, duration) {
        if (this._question && question) {
            this._innerQuestion.innerHTML = question;
            if (this._map) {
                this?.getPane().appendChild(this._question);
            this._resetLayerBounds();
            }
            clearTimeout(this._hanler);
            if (this._map) {
                this._hanler = setTimeout(() => {
                    this.getPane().removeChild(this._question);
                }, duration);
            }
        }
    },
    _resetLayerBounds: function () {
        this._bounds = latLngBounds(this._map.getBounds());
        const bounds = new Bounds(
                this._map.latLngToLayerPoint(this._bounds.getNorthWest()),
                this._map.latLngToLayerPoint(this._bounds.getSouthEast())
              );
        const size = bounds.getSize();
        DomUtil.setPosition(this._question, bounds.min);

        this._question.style.width  = size.x + 'px';
        this._question.style.height = size.y + 'px';
    },
});

export var question = function (bounds, options) {
    return new Question(bounds, options);
};
