
import UserAnswer from "./UserAnswer";
import Gobject from "./Gobject";

export default class UserAnswersCollection {
    constructor(user, gobjects, attempts, timer) {
        this.user = user;
        this.gobjects = gobjects;
        this.attempts = attempts;
        this.timer = timer;
        this.maxScore = attempts.length * 3;
        this.rightAnswersCounter = 0;
        this.rightAnswersPercent = 0;
        this.totalScore = 0;
        this.answers = [];
        this.inaccuracies = [];
        attempts.forEach((count, index) => {
            if (count === null) {
                // тест завершен нажатием кнопки "завершить"
                // считаем, что ученик четыре раза не нашел на карте объект
                count = 4;
            }

            // NaN - нет ответа
            if (count < 4 && !UserAnswer.isNoAnswerGiven(count)) {
                this.rightAnswersCounter++
            }
            if (count > 1 || UserAnswer.isNoAnswerGiven(count)) {
                // save to use with 'repeat only mistakes' mode
                // @todo array of gobjects
                this.inaccuracies.push(gobjects[index]);
            }
            this.answers[index] = new UserAnswer(Gobject.createInstance(gobjects[index]), count);
            this.totalScore += this.answers[index].getScore();
        });
        this.rightAnswersPercent = parseFloat((this.totalScore / this.maxScore * 100).toString()).toFixed();
    }
    getAnswers() {
        return this.answers;
    }
    getAnswersCount() {
        return this.answers.length;
    }
    getInaccuracies() {
        return this.inaccuracies;
    }
    getInaccuraciesCount() {
        return this.inaccuracies.length;
    }
    getMinutes() {
        return this.timer.minutes ?? 0;
    }
    getSeconds() {
        return this.timer.seconds ?? 0;
    }
    getTotalScore() {
        return this.totalScore;
    }
    getMaxScore() {
        return this.maxScore;
    }
    getPercentOfRightAnswers() {
        return this.rightAnswersPercent;
    }
    getAmountOfRightAnswers() {
        return this.rightAnswersCounter;
    }
    getUserName() {
        return this.user.fullname;
    }
    exportData(geojson = true) {
        let gobjects = this.gobjects;
        if (!geojson) {
            gobjects = this.gobjects.map(g => ({
                id: g.id,
                name: g.name,
            }));
        }
        return {
            user: this.user,
            gobjects: gobjects,
            attempts: this.attempts,
            timer: this.timer,
        };
    }
    exportDataForTable() {
        const name = this.getUserName();
        const percent = this.getPercentOfRightAnswers();
        const score = this.getTotalScore();
        const time = `${this.getMinutes().toLocaleString('ru-RU', {minimumIntegerDigits: 2})}:${this.getSeconds().toLocaleString('ru-RU', {minimumIntegerDigits: 2})}`;
        return {name, percent, score, time};
    }
    static createInstanceFromExportedData(data) {
        // @todo check data format and throw an error if its wrong
        return new UserAnswersCollection(data.user ?? {}, data.gobjects ?? [], data.attempts ?? [], data.timer ?? {});
    }
}
