import React from 'react';


const ViewObjectIcon = ({ classname }) => {
  return (
    <svg
      className={classname}
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0107 2.69767C11.6635 2.69217 13.2844 3.1532 14.6868 4.02776C16.0893 4.90232 17.2165 6.15491 17.9389 7.64151C16.4557 10.6707 13.4265 12.5854 10.0107 12.5854C6.59499 12.5854 3.56576 10.6707 2.08261 7.64151C2.80497 6.15491 3.93218 4.90232 5.33465 4.02776C6.73713 3.1532 8.35794 2.69217 10.0107 2.69767ZM10.0107 0.899902C5.51634 0.899902 1.67811 3.69542 0.123047 7.64151C1.67811 11.5876 5.51634 14.3831 10.0107 14.3831C14.5051 14.3831 18.3434 11.5876 19.8984 7.64151C18.3434 3.69542 14.5051 0.899902 10.0107 0.899902ZM10.0107 5.39431C10.6067 5.39431 11.1783 5.63107 11.5998 6.0525C12.0212 6.47393 12.2579 7.04552 12.2579 7.64151C12.2579 8.23751 12.0212 8.80909 11.5998 9.23053C11.1783 9.65196 10.6067 9.88872 10.0107 9.88872C9.41475 9.88872 8.84316 9.65196 8.42173 9.23053C8.0003 8.80909 7.76354 8.23751 7.76354 7.64151C7.76354 7.04552 8.0003 6.47393 8.42173 6.0525C8.84316 5.63107 9.41475 5.39431 10.0107 5.39431ZM10.0107 3.59655C7.78152 3.59655 5.96578 5.41229 5.96578 7.64151C5.96578 9.87074 7.78152 11.6865 10.0107 11.6865C12.24 11.6865 14.0557 9.87074 14.0557 7.64151C14.0557 5.41229 12.24 3.59655 10.0107 3.59655Z"
        fill="#6F6F6F"
      />
    </svg>
  )
}

export default ViewObjectIcon