import React from 'react';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import {Skeleton} from "@material-ui/lab";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Language from '@material-ui/icons/Language';
import ListItemIcon from '@material-ui/core/ListItemIcon';

export default function SelectRegion(props) {
    function handleRegionClick(region) {
        if (region !== props.region) {
            props.onRegionChange(region);
        }
    }

    let regions;
    if (props.region && props.regions.length) {
        regions = props.regions.map((name) => (
            <MenuItem
                key={name}
                role={undefined}
                button
                selected={props.region === name}
                onClick={(e) => {
                    handleRegionClick(name)
                }}
            >
                <ListItemIcon>
                    <Language/>
                </ListItemIcon>
                <ListItemText id={name} primary={name}/>
            </MenuItem>
        ));
    } else {
        regions = [1, 2, 3, 4, 5, 6, 7, 8].map((key) => (
            <Skeleton key={key}>
                <MenuItem button>
                    <Checkbox/>
                    <ListItemText primary="Северная Америка"/>
                </MenuItem>
            </Skeleton>
        ));
    }

    return (
        <MenuList>
            {regions}
        </MenuList>
    )
}
