
import React, {useEffect, useState} from 'react';

import { useParams, useHistory } from "react-router-dom";

import Box from '@material-ui/core/Box';
import World from "./World/World";
import { layers } from "./World/tiles";
import Result from "./Result/Result";
import Skeleton from '@material-ui/lab/Skeleton';
import shuffle from 'shuffle-array';
import {nanoid} from "nanoid";
import UserAnswersCollection from "./UserAnswersCollection";
import SignInSide from "./Auth/SignInSlide";

const MY_NAME = 'my_name';
const MY_TEST = 'my_test';
const MY_RESULT = 'my_result';

export default function ModeTest() {
    const { tid } = useParams();

    const [user, setUser] = useState({
        fullname: '',
    });

    const [questions, setQuestions] = useState({
        isLoaded: false,
        gobjects: [],
        layers: [],
    });

    const [results, setResults] = useState({
        attempts: [],
        timer: {},
        gobjects: [],
        sent: false,
    });

    const [display, setDisplay] = useState(MY_NAME);

    const history = useHistory();

    useEffect(() => {
        const url = `https://${process.env.REACT_APP_DOMAIN_API}/tests`;
        const params = new URLSearchParams();
        params.append('id', tid);
        fetch(`${url}?${params.toString()}`)
            .then(response => response.json())
            .then((test) => {
                const tl = test.layers.split(',');
                const gids = test.gobjects.split(',');

                const gparams = new URLSearchParams();
                gids.forEach(id => gparams.append('id', id));
                fetch(`https://${process.env.REACT_APP_DOMAIN_API}/gobjects?${gparams.toString()}`)
                    .then(response => response.json())
                    .then((gobjects) => {
                            setQuestions({
                                isLoaded: true,
                                gobjects: shuffle(gobjects),
                                layers: layers.filter(layer => tl.indexOf(layer.name) !== -1)
                            });
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            // @todo error handling
                        });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                // @todo error handling
            });
    }, [tid]);

    function handleDoneAnswering(attempts, timerValues, gobjects) {
        const answerCollection = new UserAnswersCollection(user, gobjects, attempts, timerValues);
        const saveAnswers = async (answersCollection) => {
            return await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/testresults`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: nanoid(8),
                    test_id: tid,
                    result: answersCollection.exportData(false),
                })
            });
            // @todo: error handling
        }
        if (results.sent) {
            // do not sent results on re-take
            setResults({
                attempts: attempts,
                timer: timerValues,
                gobjects: gobjects,
                sent: true,
            });
            setDisplay(MY_RESULT);
            return;
        }
        saveAnswers(answerCollection).then(() => {
            setResults({
                attempts: attempts,
                timer: timerValues,
                gobjects: gobjects,
                sent: true,
            });
            setDisplay(MY_RESULT);
        });
        // @todo: error handling
    }

    function handleReset() {
        history.push('/');
    }

    function handleRetake() {
        setQuestions({
            isLoaded: true,
            gobjects: shuffle(questions.gobjects),
            layers: questions.layers,
        });
        setResults({
            attempts: [],
            timer: {},
            gobjects: [],
            sent: true,
        });
        setDisplay(MY_TEST);
    }

    function handleMistakesOnly(gobjects) {
        setQuestions({
            isLoaded: true,
            gobjects: shuffle(gobjects), // gobjects contains mistakes from the test
            layers: questions.layers,
        });
        setResults({
            attempts: [],
            timer: {},
            gobjects: [],
            sent: true,
        });
        setDisplay(MY_TEST);
    }

    function handleStartTesting(fullname) {
        setUser({
            fullname: fullname
        });
        setDisplay(MY_TEST);
    }

    return (
        <Box>
            {display === MY_NAME && (
                <SignInSide onStartTestingHandler={handleStartTesting} />
            )}
            {display === MY_TEST && (
                questions.isLoaded ?
                <World
                    tileData={questions.layers}
                    gobjects={questions.gobjects}
                    onFinish={handleDoneAnswering}
                    IsConicProjection={questions.layers.filter(layer => layer.name === 'russia_conic_projection').length > 0}
                />
                :
                <Skeleton variant="rect" width={'100vw'} height={'100vh'} />
            )}
            {display === MY_RESULT && (
                <Result
                    gobjects={results.gobjects}
                    attempts={results.attempts}
                    timer={results.timer}
                    onReset={handleReset}
                    onRetake={handleRetake}
                    onMistakes={handleMistakesOnly}
                />
            )}
        </Box>
    );
}