import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Breakdown from './Breakdown';
import Paper from '@material-ui/core/Paper';
import {declOfNum} from './../Utils';
import UserAnswersCollection from './../UserAnswersCollection';

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    breakdown: {
        marginBottom: theme.spacing(4),
    },
    buttons: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    donateLink: {
        backgroundColor: theme.palette.success.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        }
    }
}));

export default function Result(props) {
    const classes = useStyles();
    // @todo users
    const userAnswers = new UserAnswersCollection({}, props.gobjects, props.attempts, props.timer);
    const lang = {
        objects: declOfNum(userAnswers.getAnswersCount(), ['объекта', 'объектов', 'объектов']),
        score: declOfNum(userAnswers.getTotalScore(), ['балл', 'балла', 'баллов']),
        minute: declOfNum(userAnswers.getMinutes(), ['минуту', 'минуты', 'минут']),
        second: declOfNum(userAnswers.getMinutes(), ['секунду', 'секунды', 'секунд']),
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                        www.maptomind.ru
                    </Typography>
                </Toolbar>
            </AppBar>
            {/* Hero unit */}
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Ваш результат {userAnswers.getPercentOfRightAnswers()}%
                </Typography>
                <Typography variant="h5" align="center" color="textSecondary" component="p">
                    Вы правильно отметили {userAnswers.getAmountOfRightAnswers()} из {userAnswers.getAnswersCount()} {lang.objects} на карте за {userAnswers.getMinutes() ? `${userAnswers.getMinutes()} ${lang.minute}` : ''} {userAnswers.getSeconds()} {lang.second} и заработали {userAnswers.getTotalScore()} {lang.score}.
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} justify="center">
                    <Grid item xs={12} md={8} lg={9}>
                        <Paper className={classes.paper}>
                            <div className={classes.breakdown}>
                                <Breakdown answers={userAnswers.getAnswers()} />
                            </div>
                            <div className={classes.buttons}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={props.onReset}
                                >
                                    В начало
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={props.onRetake}
                                >
                                    Повторить
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={userAnswers.getInaccuraciesCount() === 0}
                                    onClick={(e) => {props.onMistakes(userAnswers.getInaccuracies())}}
                                >
                                    Работа над ошибками
                                </Button>      
                                <Button 
                                    variant="outlined"
                                    component={Link}
                                    className={classes.donateLink}
                                    href="https://pay.cloudtips.ru/p/d46821b4"
                                    target="_blank"
                                    underline="none"
                                >
                                    Сказать спасибо ₽
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}