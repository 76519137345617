export class Gobject {
    /**
     *
     * @param {Number} id
     * @param {String} name
     */
    constructor(id, name) {
        this.id = id;
        this.name = name;
    }
}
