export class GobjectCollection {
    constructor(data = []) {
        this.gobjects = new Map();

        data.forEach(gobject => this.gobjects.set(gobject.id, gobject));
    }

    /**
     * @return {Gobject[]}
     */
    asArray() {
        return Array.from(this.gobjects, ([name, gobject]) => gobject);
    }
}