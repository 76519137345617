import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    noAnswer: {
        color: '#333333',
    },
    a1: {
        color: '#46bd2a',
    },
    a2: {
        color: '#ffdc1b',
    },
    a3: {
        color: '#de7e18',
    },
    a4: {
        color: '#d22e2e',
    }
}));

export default function Breakdown(props) {
    const styles = useStyles();

    /**
     *
     * @param {UserAnswer} answer
     * @param styles
     * @return string
     */
    function getTableCellStyles(answer, styles) {
        if (answer.noAnswerGiven()) {
            return styles.noAnswer;
        }

        const attempts = answer.getAttemptsNum();
        if (attempts >= 1 && attempts <= 4) {
            return styles[`a${attempts}`];
        } else {
            return styles.a4;
        }
    }
    /**
     *
     * @param {UserAnswer} answer
     * @return string
     */
    function formatAnswerNum(answer) {
        if (answer.noAnswerGiven()) {
            return '—';
        }

        return answer.getAttemptsNum().toString();
    }
    return (
        <React.Fragment>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Ваши ответы
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Объекты</TableCell>
                        <TableCell>Попытки</TableCell>
                        <TableCell>Баллы</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.answers.map((answer) => (
                        <TableRow key={answer.getName()}>
                            <TableCell>{answer.getName()}</TableCell>
                            <TableCell className={getTableCellStyles(answer, styles)}>{formatAnswerNum(answer)}</TableCell>
                            <TableCell>{answer.getScore()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}