

const HowUseIcon = () => {
  return (
    <svg width="10" height="15" viewBox="0 0 10 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.95605 0.142578C2.78083 0.142578 0.961125 1.69396 0.545285 3.74838C0.446675 4.23555 0.858999 4.64258 1.35605 4.64258C1.85311 4.64258 2.24146 4.22853 2.40456 3.759C2.77141 2.70289 3.77674 1.94258 4.95605 1.94258C6.44465 1.94258 7.65605 3.15398 7.65605 4.64258C7.65605 5.60468 7.24745 5.96108 6.32315 6.67208C5.97935 6.93668 5.59145 7.23548 5.21976 7.60628C4.03896 8.78618 4.05155 9.94988 4.05605 10.0426V11.8426H5.85605V10.0345C5.85605 10.0129 5.87675 9.49358 6.49235 8.87888C6.78035 8.59088 7.10616 8.34068 7.42026 8.09858C8.37426 7.36418 9.45605 6.53258 9.45605 4.64258C9.45605 2.16128 7.43736 0.142578 4.95605 0.142578ZM4.05605 13.6426C4.05605 13.1455 4.459 12.7426 4.95605 12.7426C5.45311 12.7426 5.85605 13.1455 5.85605 13.6426C5.85605 14.1396 5.45311 14.5426 4.95605 14.5426C4.459 14.5426 4.05605 14.1396 4.05605 13.6426Z"
      fill="currentColor"/>
    </svg>
  )
}

export default HowUseIcon