
import React from 'react';

export default function SelectedCount(props) {
    const count = Object.entries(props.selected ?? {}).length;
    return (
        <React.Fragment>
            {count > 0 &&
                <div>
                    Всего: {count}
                </div>
            }
        </React.Fragment>
    )
}