

function getFillColor(counter) {
    switch (counter) {
        case 0:
            return '#ffffff'; // initial color
        case 1:
            return '#46bd2a';
        case 2:
            return '#ffdc1b';
        case 3:
            return '#de7e18';
        default:
            return '#d22e2e';
    }
}

function getBorderColor(counter) {
    switch (counter) {
        case 0:
            return '#ffffff'; // initial color
        case 1:
            return '#267314';
        case 2:
            return '#b69d15';
        case 3:
            return '#a45e13';
        default:
            return '#922121';
    }
}


export const style = {
    weight: 1,
    color: '#000000',
    opacity: 0.5,
    fillColor: '#ffffff',
    fillOpacity: 0.5,
}
const styleOver = {
    fillColor: '#b0b0b0',
}
const styleHint = {
    fillColor: '#c15cff',
    color: '#c15cff',
}
const styleHintOver = {
    fillColor: '#d489ff',
}

export function getStyle(counter) {
    return {
        fillColor: getFillColor(counter),
        color: getBorderColor(counter),
        fillOpacity: 0.60,
        opacity: 0.60,
    }
}

export function getStyleHint() {
    return styleHint;
}

export function getStyleOver(layerGroup) {
    if (layerGroup.getLayers()[0]?.options?.fillColor === styleHint.fillColor) {
        return styleHintOver;
    } else {
        return styleOver;
    }
}

export function getStyleOut(layerGroup) {
    if (layerGroup.getLayers()[0]?.options?.fillColor === styleHintOver.fillColor) {
        return styleHint;
    } else {
        const newStyle = { ...style };
        if (layerGroup.getLayers()[0]?.options?.weight) {
            newStyle.weight = layerGroup.getLayers()[0]?.options?.weight;
            newStyle.color = layerGroup.getLayers()[0]?.options?.color;
        }
        return newStyle;
    }
}