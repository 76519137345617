import React, {useState, useEffect} from 'react';
import {nanoid} from "nanoid";
import clipboardCopy from "clipboard-copy";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

export default function UrlToCopy(props) {

    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleClickCopyUrlTest = () => {
        clipboardCopy(props.value);
        setOpen(true);
    }
    useEffect(() => {
        if (open) {
            setTimeout(() => setOpen(false), 1500);
        }
    });

    const id = nanoid(8); // input + label

    return (
        <React.Fragment>
            <InputLabel htmlFor={id}>{props.label}</InputLabel>
            <Input
                id={id}
                type="text"
                fullWidth={true}
                value={props.value}
                disabled={props.disabled}
                endAdornment={<InputAdornment position="end">
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <Tooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Скопировано"
                        >
                            <IconButton
                                aria-label="скопировать адрес ссылки"
                                onClick={handleClickCopyUrlTest}
                            >
                                <FileCopyOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </ClickAwayListener>
                </InputAdornment>}
            />
        </React.Fragment>
    );
}