
import React, {useEffect, useState} from 'react';

import { useParams } from "react-router-dom";
import UserAnswersCollection from "./UserAnswersCollection";
import EnhancedTable from "./EnhancedTable";
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    donateLink: {
        backgroundColor: theme.palette.success.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        }
    }
}));

export default function ModeTestResults() {
    const classes = useStyles();

    const { tid } = useParams();

    const [results, setResults] = useState(null);

    useEffect(() => {
        const url = `https://${process.env.REACT_APP_DOMAIN_API}/testresults`;
        const params = new URLSearchParams();
        params.append('tid', tid);
        fetch(`${url}?${params.toString()}`)
            .then(response => response.json())
            .then((testresults) => {
                setResults(
                    testresults.map(testresult => {
                        const answerCollection = UserAnswersCollection.createInstanceFromExportedData(testresult.result);
                        return answerCollection.exportDataForTable();
                    })
                );
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                setResults([]);
            });
    }, [tid]);

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                        www.maptomind.ru
                    </Typography>
                    <Button 
                        component={Link}
                        className={classes.donateLink}
                        href="https://pay.cloudtips.ru/p/d46821b4"
                        target="_blank"
                        underline="none"
                    >
                        Сказать спасибо ₽
                    </Button>
                </Toolbar>
            </AppBar>
            {/* Hero unit */}
            <Container maxWidth="lg" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Результаты тестирования
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="lg" component="main">
                <Grid container spacing={5} justify="center">
                    <Grid item xs={12} md={8} lg={9}>
                        <EnhancedTable rows={results} />
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}