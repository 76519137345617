
import React, {useState, useEffect} from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

export default function SelectMapLayers(props) {
    const reliefTileKeys = Object.keys(props.tileReliefLabels);
    const tiles = Object.entries(props.tileLabels);

    const [selectedRelief, setSelectedRelief] = useState(reliefTileKeys[0]);

    useEffect(() => {
        for (const key in props.selectedTiles) {
            if (props.selectedTiles[key].checked) {
                setSelectedRelief(key);
                break;
            }
        }
    }, [props.selectedTiles]);

    function handleChange(name, e) {
        if (e.target.checked) {
            props.onLayerPick(name);
        } else {
            props.onLayerUnpick(name);
        }
    }

    return (
        <FormControl component="fieldset">
            <RadioGroup
                defaultValue={reliefTileKeys[0]}
            >
                {reliefTileKeys.map((key) => (
                    <FormControlLabel
                        control={<Radio 
                            checked={props.selectedTiles[key].checked}
                            disabled={props.selectedTiles[key].disabled}
                            name={key}
                            onChange={(e) => {
                                props.onLayerPick(key);
                                props.onLayerUnpick(selectedRelief);
                                setSelectedRelief(key);
                            }}
                        />}
                        label={props.tileReliefLabels[key]}
                        value={key}
                        key={key}
                    />
                ))}
            </RadioGroup>
            
            {tiles.map(([name, label]) => (
                <FormControlLabel
                    control={<Checkbox
                        checked={props.selectedTiles[name].checked}
                        disabled={props.selectedTiles[name].disabled}
                        name={name}
                        onChange={(e) => {handleChange(name, e)}}
                    />}
                    label={label}
                    key={name}
                />
            ))}
        </FormControl>
    )
}
