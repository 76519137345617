
import { useMemo } from "react"


const MobileToggler = ({ classname, children, onToggle, active }) => {

  const classnames = useMemo(() => {
    return `${classname} ${!active ? "off": ""}`
  }, [classname, active])

  return (
    <div className="mobile-toggler-wrap">
      <div className={classnames} onClick={onToggle}>
        <div className="toggle-icon-wrap">
          {children}
        </div>
      </div>
    </div>
  )
}

export default MobileToggler