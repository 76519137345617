import { createLayerComponent } from '@react-leaflet/core';
import { Question as QuestionLeaflet } from '../Leaflet/layer/Question';

const Question = createLayerComponent(
    (props, context) => {
        const instance = new QuestionLeaflet(props);
        return { instance, context: { ...context, overlayContainer: instance } }
    }
)

export default Question;
