
export class CategoryCollection {
    constructor(data = []) {
        this.categories = new Map();

        data.forEach(category => this.categories.set(category.name, category));
    }

    /**
     * @param {String} categoryName
     */
    getCategory(categoryName) {
        return this.categories.get(categoryName);
    }

    getDefaultCategory() {
        return this.categories.entries().next().value[1];
    }

    getNames() {
        return Array.from(this.categories, ([name, category]) => name);
    }
}
