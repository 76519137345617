import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SelectRegion from "./SelectRegion";
import SelectCategory from "./SelectCategory";
import SelectObjects from "./SelectObjects";
import Selected from "./Selected";
import SelectedCount from "./SelectedCount";
import {Alert} from "@material-ui/lab";
import SelectMapLayers from "./SelectMapLayers";

import UrlToCopy from "./UrlToCopy";

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
    },
    toolbar: {
        display: 'grid',
        gridTemplateColumns: '180px 1fr 370px',
        columnGap: '0px',
        flexWrap: 'wrap',
        "@media (max-width: 720px)": {
            gridTemplateColumns: '1fr 130px',
            gridTemplateRows: '1fr 1fr',
            gridTemplateAreas: "'a b' 'c c'",
            paddingBottom: '1rem',
            paddingTop: '1rem',
            rowGap: '.5rem'
        },
        "@media (max-width:500px)": {
            // gridTemplateRows: '1fr 1fr',
            // gridTemplateColumns: '180px 1fr 1fr',
            // gridTemplateAreas: "'a b' 'c c'",
          },
    },
    toolbarTitle: {
        flexGrow: 1,
        "@media (max-width: 720px)": {
            gridArea: 'a',
        }
    },
    iconstyle: {
        order: 1,
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        "@media (max-width:1000px)": {
            display: "flex",
            transform: "scale(1)",
          },
          "@media (max-width:500px)": {
            display: "flex",
            transform: "scale(1.5, 1.5)",
          },
    },
    linkFontStyle:{
        order: 2,
        margin: '0px',
        "@media (max-width:1000px)": {
            display: "none",
        },
    },
    linkstyle: {
        display: 'flex',
        flex: '1 1',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '15px',
        marginRight: '15px',
        alignItems: 'center',
        "@media (max-width: 720px)": {
            gridArea: 'b'
        },
        "@media (max-width:500px)": {
            gap: '7vw',
            marginLeft: '24px',
        },
    },
    linkk: {
        flexDirection: 'row',
        display: 'flex',
        margin: '0px',
        gap: '5px',
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    cardSelected: {
        marginBottom: theme.spacing(2),
    },
    cardWarning: {
        marginBottom: theme.spacing(2),
    },
    cardNextButton: {
        marginBottom: theme.spacing(2),
    },
    link: {
        marginBottom: theme.spacing(2),
    },
    buttonMarginTB: {
        marginBottom: '8px',
        marginTop: '8px',
    },
    headerLinksWrap: {
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row nowrap',
        gap: '1rem',
        justifyContent: 'space-between',
        "@media (max-width: 720px)": {
            gridArea: 'c',
            justifyContent: 'flex-start',
        }
    },
    prepareLink: {
        backgroundColor: '#2480D6',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#1068ba'
        }
    },
    donateLink: {
        backgroundColor: theme.palette.success.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        },
        "@media (max-width:500px)": {
            gridColumn: '1/2',
            gridRow: '2/3'
          },
    }
}));

export default function Filter(props) {
    const classes = useStyles();

    function handleGobjectPick(id, name, category, region) {
        props.onObjectPick(id, name, category, region);
    }

    function handleGobjectUnpick(id, category, region) {
        props.onObjectUnpick(id, category, region);
    }

    function handleCategoryClick(category) {
        props.onCategoryClick(category);
    }

    function handleCategoryPick(category) {
        props.onCategoryPick(category);
    }

    function handleCategoryUnpick(category) {
        props.onCategoryUnpick(category);
    }

    function handleRegionChange(region) {
        props.onRegionChange(region);
    }

    function handleLayerPick(name) {
        props.onMapLayerPick(name);
    }

    function handleLayerUnpick(name) {
        props.onMapLayerUnpick(name);
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                        www.maptomind.ru
                    </Typography>
                    <nav className={classes.linkstyle}>
                        <Link variant="button" color="textPrimary" href="https://vk.com/maptomind" className={classes.linkk}>
                            <p className={classes.linkFontStyle}>Мы ВКонтакте</p>

                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.iconstyle}>
                                <path d="M14.0603 0.322266H7.9243C2.3203 0.322266 0.987305 1.65527 0.987305 7.24927V13.3853C0.987305 18.9883 2.3103 20.3223 7.9143 20.3223H14.0503C19.6533 20.3223 20.9873 18.9993 20.9873 13.3953V7.25927C20.9873 1.65527 19.6643 0.322266 14.0603 0.322266ZM17.1333 14.5923H15.6743C15.1223 14.5923 14.9563 14.1453 13.9663 13.1553C13.1023 12.3223 12.7373 12.2183 12.5183 12.2183C12.2163 12.2183 12.1333 12.3013 12.1333 12.7183V14.0303C12.1333 14.3853 12.0183 14.5933 11.0913 14.5933C10.1916 14.5328 9.31923 14.2595 8.5459 13.7958C7.77258 13.3321 7.12047 12.6913 6.6433 11.9263C5.51047 10.5163 4.72225 8.8614 4.3413 7.09327C4.3413 6.87427 4.4243 6.67627 4.8413 6.67627H6.2993C6.6743 6.67627 6.8093 6.84327 6.9563 7.22827C7.6643 9.31227 8.8723 11.1243 9.3623 11.1243C9.5503 11.1243 9.63231 11.0413 9.63231 10.5723V8.42627C9.57031 7.44727 9.0503 7.36427 9.0503 7.01027C9.05698 6.91689 9.09977 6.8298 9.16959 6.76744C9.23941 6.70509 9.33078 6.67239 9.4243 6.67627H11.7163C12.0293 6.67627 12.1333 6.83227 12.1333 7.20727V10.1033C12.1333 10.4163 12.2683 10.5203 12.3623 10.5203C12.5503 10.5203 12.6953 10.4163 13.0393 10.0723C13.7782 9.17121 14.3818 8.16734 14.8313 7.09227C14.8772 6.96313 14.9642 6.85257 15.0788 6.77746C15.1935 6.70235 15.3296 6.66681 15.4663 6.67627H16.9253C17.3623 6.67627 17.4553 6.89527 17.3623 7.20727C16.8318 8.3957 16.1754 9.52381 15.4043 10.5723C15.2473 10.8123 15.1843 10.9373 15.4043 11.2183C15.5493 11.4373 16.0603 11.8643 16.4043 12.2703C16.9042 12.7689 17.3193 13.3458 17.6333 13.9783C17.7583 14.3843 17.5493 14.5923 17.1333 14.5923Z" fill="#383838"/>
                            </svg>

                        </Link>
                        <Link variant="button" color="textPrimary" href="https://t.me/maptomind" className={classes.linkk}>
                            <p className={classes.linkFontStyle}>Мы в Telegram</p>

                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.iconstyle}>
                                <path d="M10.6882 0.322266C5.16823 0.322266 0.688232 4.80227 0.688232 10.3223C0.688232 15.8423 5.16823 20.3223 10.6882 20.3223C16.2082 20.3223 20.6882 15.8423 20.6882 10.3223C20.6882 4.80227 16.2082 0.322266 10.6882 0.322266ZM15.3282 7.12227C15.1782 8.70227 14.5282 12.5423 14.1982 14.3123C14.0582 15.0623 13.7782 15.3123 13.5182 15.3423C12.9382 15.3923 12.4982 14.9623 11.9382 14.5923C11.0582 14.0123 10.5582 13.6523 9.70823 13.0923C8.71823 12.4423 9.35823 12.0823 9.92823 11.5023C10.0782 11.3523 12.6382 9.02227 12.6882 8.81227C12.6952 8.78046 12.6943 8.74744 12.6855 8.71607C12.6768 8.6847 12.6606 8.65593 12.6382 8.63227C12.5782 8.58227 12.4982 8.60227 12.4282 8.61227C12.3382 8.63227 10.9382 9.56227 8.20823 11.4023C7.80823 11.6723 7.44823 11.8123 7.12823 11.8023C6.76823 11.7923 6.08823 11.6023 5.57823 11.4323C4.94823 11.2323 4.45823 11.1223 4.49823 10.7723C4.51823 10.5923 4.76823 10.4123 5.23823 10.2223C8.15823 8.95227 10.0982 8.11227 11.0682 7.71227C13.8482 6.55227 14.4182 6.35227 14.7982 6.35227C14.8782 6.35227 15.0682 6.37227 15.1882 6.47227C15.2882 6.55227 15.3182 6.66227 15.3282 6.74227C15.3182 6.80227 15.3382 6.98227 15.3282 7.12227Z" fill="#383838"/>
                            </svg>
                        </Link>
                        <Link variant="button" color="textPrimary" href="https://t.me/+dGY4I3-6Zrs1ZjRi" className={classes.linkk}>
                            <p className={classes.linkFontStyle}>Чат Telegram</p>

                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.iconstyle}>
                                <path d="M4.13943 12.1975H11.6402V10.3223H4.13943V12.1975ZM4.13943 9.38467H15.3906V7.50947H4.13943V9.38467ZM4.13943 6.57188H15.3906V4.69668H4.13943V6.57188ZM0.389038 19.6982V2.82149C0.389038 2.30581 0.572807 1.8642 0.940346 1.49666C1.30788 1.12912 1.74918 0.945666 2.26423 0.946291H17.2658C17.7815 0.946291 18.2231 1.13006 18.5906 1.4976C18.9582 1.86514 19.1416 2.30643 19.141 2.82149V14.0727C19.141 14.5883 18.9572 15.0299 18.5897 15.3975C18.2221 15.765 17.7808 15.9485 17.2658 15.9479H4.13943L0.389038 19.6982Z" fill="#383838"/>
                            </svg>
                        </Link>
                    </nav>
                    <div className={classes.headerLinksWrap}>
                        <Button 
                            component={Link}
                            className={classes.prepareLink}
                            href="/podgotovka-k-ege"
                            underline="none"
                        >
                            Подготовка к ЕГЭ
                        </Button>
                        <Button 
                            component={Link}
                            className={classes.donateLink}
                            href="https://pay.cloudtips.ru/p/d46821b4"
                            target="_blank"
                            underline="none"
                        >
                            Поддержать проект ₽
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            {/* Hero unit */}
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Изучите карту
                </Typography>
                <Typography variant="h5" align="center" color="textSecondary" component="p">
                    Выберите географические объекты из списка, затем найдите их на карте мира.
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
                <Grid container spacing={5} alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={4}>
                        <Card>
                            <CardContent>
                                <div className={classes.cardPricing}>
                                    <Typography variant="h6" color="textSecondary">
                                        1. Выберите регион
                                    </Typography>
                                </div>
                                <SelectRegion
                                    regions={props.regions}
                                    region={props.region}
                                    onRegionChange={handleRegionChange}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <CardContent>
                                <div className={classes.cardPricing}>
                                    <Typography variant="h6" color="textSecondary">
                                        2. Выберите категории
                                    </Typography>
                                </div>
                                <SelectCategory
                                    region={props.region}
                                    categories={props.categories}
                                    category={props.category}
                                    selectedGobjects={props.selectedGobjects}
                                    selectedCategories={props.selectedCategories}
                                    onCategoryPick={handleCategoryPick}
                                    onCategoryUnpick={handleCategoryUnpick}
                                    onCategoryClick={handleCategoryClick}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <CardContent>
                                <div className={classes.cardPricing}>
                                    <Typography variant="h6" color="textSecondary">
                                        3. Выберите объекты
                                    </Typography>
                                </div>
                                <SelectObjects
                                    region={props.region}
                                    category={props.category}
                                    gobjects={props.gobjects}
                                    selectedGobjects={props.selectedGobjects}
                                    onObjectPick={handleGobjectPick}
                                    onObjectUnpick={handleGobjectUnpick}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <div className={classes.cardPricing}>
                                    <Typography variant="h6" color="textSecondary">
                                        4. Выберите слои карты
                                    </Typography>
                                </div>
                                <div>
                                    <SelectMapLayers
                                        selectedTiles={props.selectedTiles}
                                        tileReliefLabels={props.tileReliefLabels}
                                        tileLabels={props.tileLabels}
                                        onLayerPick={handleLayerPick}
                                        onLayerUnpick={handleLayerUnpick}
                                    />
                                </div>
                                <div>
                                    <Alert severity="info">
                                        Если нужна политическая карта, то отключите слои и выберите категорию страны во
                                        втором списке выше.
                                    </Alert>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <div className={classes.cardPricing}>
                                    <Typography variant="h6" color="textSecondary">
                                        5. Проверьте себя
                                    </Typography>
                                </div>
                                <div className={classes.cardSelected}>
                                    <Selected
                                        selected={props.selectedGobjects}
                                        onObjectUnpick={handleGobjectUnpick}
                                    />
                                </div>
                                <div className={classes.cardSelected}>
                                    <SelectedCount
                                        selected={props.selectedGobjects}
                                    />
                                </div>
                                {Object.keys(props.selectedGobjects ?? {}).length > 0 && (
                                    props.isShowShortUrl ?
                                    <React.Fragment>
                                        <div className={classes.link}>
                                            <div className={classes.link}>
                                                <Grid container spacing={5} alignItems="flex-start">
                                                    <Grid item xs={12} sm={12} md={10}>
                                                        <UrlToCopy
                                                            label="Быстрая ссылка"
                                                            value={props.shortUrl}
                                                            disabled={Object.keys(props.selectedGobjects ?? {}).length === 0}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                        <div className={classes.cardSelected}>
                                            <Alert severity="info">
                                                Сохраните ссылку, чтобы в следующий раз не выбирать эти же объекты
                                                заново.
                                            </Alert>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => props.generateFastLink()}
                                        className={classes.buttonMarginTB}
                                        disabled={props.buttonShortUrl.isDisabled}
                                    >
                                        {props.buttonShortUrl.text}
                                    </Button>
                                )}
                                <div className={classes.cardNextButton}>
                                    <Button mt={2} mb={2}
                                        variant="contained"
                                        color="primary"
                                        onClick={props.onDoneFiltering}
                                        disabled={Object.keys(props.selectedGobjects ?? {}).length === 0}
                                    >
                                        Проверить себя
                                    </Button>
                                </div>
                                {!Object.keys(props.selectedGobjects ?? {}).length > 0 && (
                                    <div>
                                        <Alert severity="warning">
                                            Выберите хотя бы один объект из списка выше, чтобы разблокировать кнопку.
                                        </Alert>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <div className={classes.cardPricing}>
                                    <Typography variant="h6" color="textSecondary">
                                        6. Проверьте других
                                    </Typography>
                                </div>
                                <div className={classes.cardNextButton}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={props.onCreateTest}
                                        disabled={Object.keys(props.selectedGobjects ?? {}).length === 0 || !!props.url}
                                    >
                                        Создать тест для группы
                                    </Button>
                                </div>
                                {Object.keys(props.selectedGobjects ?? {}).length === 0 && (
                                    <div>
                                        <Alert severity="warning">
                                            Выберите хотя бы один объект из списка выше, чтобы разблокировать кнопку.
                                        </Alert>
                                    </div>
                                )}
                                {Object.keys(props.selectedGobjects ?? {}).length > 0 && props.url && (
                                    <div className={classes.link}>
                                        <Grid container spacing={5} alignItems="flex-start">
                                            <Grid item xs={12} sm={12} md={5}>
                                                <UrlToCopy
                                                    label="Тестирование"
                                                    value={props.url.getTestUrl()}
                                                    disabled={Object.keys(props.selectedGobjects ?? {}).length === 0}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                {Object.keys(props.selectedGobjects ?? {}).length > 0 && props.url && (
                                    <div className={classes.link}>
                                        <Grid container spacing={5} alignItems="flex-start">
                                            <Grid item xs={12} sm={12} md={5}>
                                                <UrlToCopy
                                                    label="Результаты тестирования"
                                                    value={props.url.getTestResultsUrl()}
                                                    disabled={Object.keys(props.selectedGobjects ?? {}).length === 0}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                {Object.keys(props.selectedGobjects ?? {}).length > 0 && props.url && (
                                    <div className={classes.cardNextButton}>
                                        <Alert severity="info">
                                            Отправьте первую ссылку группе. Используйте вторую ссылку для просмотра результатов тестирования.
                                        </Alert>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}