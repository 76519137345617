import { AppBar, Button, Link, Toolbar, makeStyles } from '@material-ui/core';
import React from 'react'
import { useState } from 'react';
import desktopMapExample from './assets/desktopMapExample.png'
import mobileMapExample from './assets/mobileMapExample.png'
import { ReactComponent as ArrowBack } from './assets/arrow-back.svg'

const chaptersList = [
  {
    name: 'Страны и столицы',
    id: '1',
    subChapters: [
      {
        name: "Африка",
        id: "Африка",
        link: 'https://www.maptomind.ru/quick_test/pupuxs7k'
      },
      {
        name: "Южная Америка",
        id: "Южная Америка",
        link: 'https://www.maptomind.ru/quick_test/u28o6h4b'
      },
      {
        name: "Северная Америка",
        id: "Северная Америка",
        link: 'https://www.maptomind.ru/quick_test/g9jc94x4'
      },
      {
        name: "Европа",
        id: "Европа",
        link: 'https://www.maptomind.ru/quick_test/e2qyib2l'
      },
      {
        name: "Азия",
        id: "Азия",
        link: 'https://www.maptomind.ru/quick_test/shmpnxvj'
      },
      {
        name: "Австралия и Океания",
        id: "Австралия и Океания",
        link: 'https://www.maptomind.ru/quick_test/tlddds9c'
      },
    ]
  },
  {
    name: 'Физическая география мира',
    id: '2',
    subChapters: [
      {
        name: "Моря, заливы, проливы",
        id: "Моря, заливы, проливы",
        link: 'https://www.maptomind.ru/quick_test/lwt2kkci'
      },
      {
        name: "Острова, полуострова",
        id: "Острова, полуострова",
        link: 'https://www.maptomind.ru/quick_test/j191ggg1'
      },
      {
        name: "Равнины, возвышенности",
        id: "Равнины, возвышенности",
        link: 'https://www.maptomind.ru/quick_test/puevutst'
      },
      {
        name: "Горы",
        id: "Горы",
        link: 'https://www.maptomind.ru/quick_test/hc0kg891'
      },
      {
        name: "Реки",
        id: "Реки",
        link: 'https://www.maptomind.ru/quick_test/d2n2uk40'
      },
      {
        name: "Озёра",
        id: "Озёра",
        link: 'https://www.maptomind.ru/quick_test/8qm33wji'
      },
      {
        name: "Пустыни",
        id: "Пустыни",
        link: 'https://www.maptomind.ru/quick_test/7u900nnx'
      },
      {
        name: "Каналы, водопады",
        id: "Каналы, водопады",
        link: 'https://www.maptomind.ru/quick_test/zl22nk30'
      },
    ]
  },
  {
    name: 'Россия',
    id: '3',
    subChapters: [
      {
        name: "Моря, заливы, проливы",
        id: "Моря, заливы, проливы",
        link: 'https://www.maptomind.ru/quick_test/86r645co'
      },
      {
        name: "Острова, полуострова, мысы",
        id: "Острова, полуострова, мысы",
        link: 'https://www.maptomind.ru/quick_test/oilm87nh'
      },
      {
        name: "Равнины, возвышенности",
        id: "Равнины, возвышенности",
        link: 'https://www.maptomind.ru/quick_test/qmi9ny7u'
      },
      {
        name: "Горы",
        id: "Горы",
        link: 'https://www.maptomind.ru/quick_test/n78zh1ai'
      },
      {
        name: "Реки",
        id: "Реки",
        link: 'https://www.maptomind.ru/quick_test/2chwc9zp'
      },
      {
        name: "Озёра, каналы",
        id: "Озёра, каналы",
        link: 'https://www.maptomind.ru/quick_test/fj4cu57o'
      },
      {
        name: "Природные зоны",
        id: "Природные зоны",
        link: 'https://www.maptomind.ru/quick_test/x7ci86qg'
      },
      {
        name: "Почвы",
        id: "Почвы",
        link: 'https://www.maptomind.ru/quick_test/n3k6e4ez'
      },
      {
        name: "ООПТ",
        id: "ООПТ",
        link: 'https://www.maptomind.ru/quick_test/y65b37my'
      },
      {
        name: "Административно-территориальное деление",
        id: "Административно-территориальное деление",
        link: 'https://www.maptomind.ru/quick_test/6htttfju'
      },
      {
        name: "Административно-территориальное деление + центры",
        id: "Административно-территориальное деление + центры",
        link: 'https://www.maptomind.ru/quick_test/wzfaiwbb'
      },
    ]
  },
  {
    name: 'Физическая география по регионам мира',
    id: '4',
    subChapters: [
      {
        name: "Африка",
        id: "Африка",
        link: 'https://www.maptomind.ru/quick_test/gnn1bp4k'
      },
      {
        name: "Южная Америка",
        id: "Южная Америка",
        link: 'https://www.maptomind.ru/quick_test/neot4g2f'
      },
      {
        name: "Северная Америка",
        id: "Северная Америка",
        link: 'https://www.maptomind.ru/quick_test/6wzaoi5c'
      },
      {
        name: "Европа",
        id: "Европа",
        link: 'https://www.maptomind.ru/quick_test/xak0w6m1'
      },
      {
        name: "Азия",
        id: "Азия",
        link: 'https://www.maptomind.ru/quick_test/9tbr6f2u'
      },
      {
        name: "Австралия и Океания",
        id: "Австралия и Океания",
        link: 'https://www.maptomind.ru/quick_test/788z0fre'
      },
    ]
  },
]

const useStyles = makeStyles((theme) => ({
  '@global': {
      ul: {
          margin: 0,
          padding: 0,
          listStyle: 'none',
      },
  },
  appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      display: 'flex',
  },
  toolbar: {
      display: 'grid',
      gridTemplateColumns: '196px 1fr 200px',
      paddingLeft: '120px',
      columnGap: '0px',
      flexWrap: 'wrap',
      "@media (max-width: 820px)": {
        paddingLeft: '24px',
      },
      "@media (max-width: 600px)": {
          gridTemplateColumns: '196px 1fr 170px',
          gridTemplateRows: '1fr 1fr',
          gridTemplateAreas: "'a d b' 'c d b'",
          paddingBottom: '1rem',
          paddingTop: '1rem',
          rowGap: '.5rem'
      },
      "@media (max-width: 425px)": {
          gridTemplateColumns: '196px 1fr 140px',
      },
      "@media (max-width: 375px)": {
          gridTemplateColumns: '196px 1fr',
          gridTemplateRows: '1fr 1fr',
          gridTemplateAreas: "'a b' 'c b'",
      }
  },
  toolbarTitleWrap: {
      display: 'flex',
  },
  toolbarTitle: {
      width: '190.58px',
      textDecoration: 'none',
      color: '#000',
      fontSize: '1.25rem',
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      lineHeight: '1.6',
      fontWeight: '500',
      border: '.5px solid rgb(56, 56, 56)',
      borderRadius: '4px',
      textAlign: 'center',
      "@media (max-width: 600px)": {
          gridArea: 'a',
      }
  },
  iconstyle: {
      order: 1,
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      "@media (max-width:940px)": {
          display: "block",
          transform: "scale(1)",
        },
        "@media (max-width:600px)": {
          // display: "block",
          width: '32px',
          height: '32px',
        },
  },
  linkFontStyle:{
      order: 2,
      margin: '0px',
      "@media (max-width:940px)": {
          display: "none",
      },
  },
  linkstyle: {
      display: 'flex',
      flex: '1 1',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: '15px',
      marginRight: '15px',
      alignItems: 'center',
      "@media (max-width: 600px)": {
          gridArea: 'b',
          gap: '7vw',
          marginLeft: '24px',
      },
      "@media (max-width: 425px)": {
          gap: '.5rem',
          margin: '0'
      }
  },
  linkk: {
      flexDirection: 'row',
      display: 'flex',
      margin: '0px',
      gap: '5px',
      "@media (max-width: 600px)": {
        width: '32px',
        height: '32px'
      }
  },
  link: {
      marginBottom: theme.spacing(2),
  },
  headerLinksWrap: {
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      gap: '1rem',
      justifyContent: 'flex-end',
      "@media (max-width: 600px)": {
          gridArea: 'c',
          justifyContent: 'flex-start',
      }
  },
  prepareLink: {
      backgroundColor: '#2480D6',
      color: '#fff',
      '&:hover': {
          backgroundColor: '#1068ba'
      }
  },
  donateLink: {
      backgroundColor: theme.palette.success.main,
      color: '#fff',
      '&:hover': {
          backgroundColor: theme.palette.success.dark,
      },
      "@media (max-width:500px)": {
          gridColumn: '1/2',
          gridRow: '2/3'
        },
  },

  containerWrap: {
    margin: '0 120px',
    "@media (max-width: 820px)": {
      margin: '0 24px',
    }
  },
  mainContentWrap: {
    padding: '2.5rem 0 7.8125rem',
    "& > h2": {
      fontWeight: '600',
      fontSize: '30px',
      lineHeight: '120.9%',
      color: '#606060',
      "& > span": {
        color: '#2480D6',
      }
    },
  },
  paragraphsWrap: {
    display: 'flex',
    flexFlow: 'column nowrap',
    gap: '1rem',
    // flexFlow: 'row wrap',
    "@media (max-width: 768px)": {
      flexFlow: 'column nowrap',
      // gap: '1rem',
    },

    '& > p': {
      flexBasis: '100%',
      // flexBasis: '50%',
      color: '#606060',
      fontSize: '18px',
      margin: '0',

      "@media (max-width: 768px)": {
        flexBasis: '100%',
        paddingBottom: '.875rem',
      },

      // "&:nth-of-type(2n+1)": {
      //   borderRight: '1px solid #A39C98',
      //   paddingRight: '1rem',
      //   "@media (max-width: 768px)": {
      //     borderRight: 'none',
      //   }
      // },
      // "&:nth-of-type(2n)": {
      //   paddingLeft: '25px',
      //   "@media (max-width: 768px)": {
      //     paddingLeft: "0",
      //   }
      // },
      "&:nth-of-type(1)": {
        "@media (max-width: 768px)": {
          borderBottom: '1px solid #C9C9C9'
        }
      },
      "&:nth-of-type(3)": {
        // paddingTop: '1rem',
        // paddingRight: '1rem',
        "@media (max-width: 768px)": {
          paddingTop: "0",
        }
      },
    }
  },
  chapters: {
    marginTop: '1.5rem'
  },
  mobileBackButton: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '.875rem',
    cursor: 'pointer',
    marginBottom: '1.125rem',

    "@media (min-width: 769px)": {
      display: 'none',
    },

    "& > p": {
      fontSize: '25px',
      color: "#606060",
      margin: '0',
    }
  },
  chaptersTitle: {
    fontSize: '1.25rem',
    color: '#606060',
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: '.5rem',
    alignItems: 'center',

    "& > span": {
      cursor: 'pointer',
    }
  },
  choseChapter: {

    "@media (max-width: 768px)": {
      display: 'none'
    }
  },
  chaptersNote: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '.25rem',
    textDecoration: 'underline',

    "@media (max-width: 768px)": {
      fontSize: '25px',
    }
  },
  circle: {
    marginTop: '2px',
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    background: '#606060',
    "@media (max-width: 768px)": {
      display: 'none'
    }
  },
  chaptersWrap: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: '1px',
    border: '1px solid #A39C98',
    background: '#A39C98',
    borderRadius: '7px',
    marginTop: '.25rem',
    overflow: 'hidden',

    "@media (max-width: 1000px)": {
      flexFlow: 'column nowrap',
    }
  },
  subChaptersWrap: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row wrap',
    gap: '1px',
    border: '1px solid #A39C98',
    background: '#A39C98',
    borderRadius: '7px',
    marginTop: '.25rem',
    overflow: 'hidden',
    "& > div": {
      "@media (max-width: 1000px)": {
        display: 'none',
      },
    },
    "&.odd > div": {
      "@media (min-width: 1001px)": {
        width: "calc((100% - 1px)/2)",
        background: '#fff'
      }
    },

    "@media (max-width: 1000px)": {
      flexFlow: 'column nowrap',
    }
  },
  chapterWrap: {
    flexBasis: 'calc((100% - 1px)/2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '100px 20px',
    color: "#606060",
    background: '#fff',
    cursor: 'pointer',
    transition: 'background .15s ease-in-out, color .15s ease-in-out',
    "@media (max-width: 1140px)": {
      padding: '60px 20px',
    },

    "&.activeChapter, &:hover": {
      background: '#2480D6',
      color: '#fff',
    },

    "& > p": {
      textAlign: 'center',
      fontSize: '2.5rem',
      fontWeight: '500',
      margin: '0',

      "@media (max-width: 1140px)": {
        fontSize: '1.875rem'
      },
    }
  },

  subChapterWrap: {
    position: 'relative',
    flexBasis: 'calc((100% - 1px)/2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "#606060",
    background: '#fff',
    cursor: 'pointer',
    transition: 'background .15s ease-in-out, color .15s ease-in-out',


    "&.activeChapter, &:hover": {
      background: '#2480D6',
      "& > a": {
        color: '#fff',
      }
    },

    "& > a": {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '50px 20px',
      width: '100%',
      height: '100%',
      color: '#606060',
      textDecoration: 'none',
      transition: 'color .15s ease-in-out',

      "@media (max-width: 1140px)": {
        padding: '40px 20px',
      },
    },

    "& > a > p": {
      textAlign: 'center',
      fontSize: '2.5rem',
      fontWeight: '500',
      margin: '0',
      "@media (max-width: 1140px)": {
        fontSize: '1.875rem'
      },
    }
  },

  mapExample: {
    paddingTop: '50px',
    "& > p": {
      margin: '0 0 .5rem 0',
      color: '#606060',
      fontSize: '1.125rem',
      fontWeight: '500'
    }
  },
  desktopImage: {
    width: '100%',
    "@media (max-width: 600px)": {
      display: 'none',
    }
  },
  mobileImage: {
    width: '100%',
    "@media (min-width: 601px)": {
      display: 'none'
    }
  },
}));

const PrepareForUse = () => {
  const [chapter, setChapter] = useState('');
  const [subChapters, setSubChapters] = useState(null)
  const classes = useStyles();

  const handleClear = () => {
    setChapter('')
    setSubChapters(null)
  }

  const setData = item => {
    setChapter(item.name)
    setSubChapters([...item.subChapters])
  }

  return (
    <>
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
                    <div className={classes.toolbarTitleWrap}>
                      <a href='/' className={classes.toolbarTitle}>
                          www.maptomind.ru
                      </a>
                    </div>
                    <nav className={classes.linkstyle}>
                        <Link variant="button" color="textPrimary" href="https://vk.com/maptomind" className={classes.linkk}>
                            <p className={classes.linkFontStyle}>Мы ВКонтакте</p>

                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.iconstyle}>
                                <path d="M14.0603 0.322266H7.9243C2.3203 0.322266 0.987305 1.65527 0.987305 7.24927V13.3853C0.987305 18.9883 2.3103 20.3223 7.9143 20.3223H14.0503C19.6533 20.3223 20.9873 18.9993 20.9873 13.3953V7.25927C20.9873 1.65527 19.6643 0.322266 14.0603 0.322266ZM17.1333 14.5923H15.6743C15.1223 14.5923 14.9563 14.1453 13.9663 13.1553C13.1023 12.3223 12.7373 12.2183 12.5183 12.2183C12.2163 12.2183 12.1333 12.3013 12.1333 12.7183V14.0303C12.1333 14.3853 12.0183 14.5933 11.0913 14.5933C10.1916 14.5328 9.31923 14.2595 8.5459 13.7958C7.77258 13.3321 7.12047 12.6913 6.6433 11.9263C5.51047 10.5163 4.72225 8.8614 4.3413 7.09327C4.3413 6.87427 4.4243 6.67627 4.8413 6.67627H6.2993C6.6743 6.67627 6.8093 6.84327 6.9563 7.22827C7.6643 9.31227 8.8723 11.1243 9.3623 11.1243C9.5503 11.1243 9.63231 11.0413 9.63231 10.5723V8.42627C9.57031 7.44727 9.0503 7.36427 9.0503 7.01027C9.05698 6.91689 9.09977 6.8298 9.16959 6.76744C9.23941 6.70509 9.33078 6.67239 9.4243 6.67627H11.7163C12.0293 6.67627 12.1333 6.83227 12.1333 7.20727V10.1033C12.1333 10.4163 12.2683 10.5203 12.3623 10.5203C12.5503 10.5203 12.6953 10.4163 13.0393 10.0723C13.7782 9.17121 14.3818 8.16734 14.8313 7.09227C14.8772 6.96313 14.9642 6.85257 15.0788 6.77746C15.1935 6.70235 15.3296 6.66681 15.4663 6.67627H16.9253C17.3623 6.67627 17.4553 6.89527 17.3623 7.20727C16.8318 8.3957 16.1754 9.52381 15.4043 10.5723C15.2473 10.8123 15.1843 10.9373 15.4043 11.2183C15.5493 11.4373 16.0603 11.8643 16.4043 12.2703C16.9042 12.7689 17.3193 13.3458 17.6333 13.9783C17.7583 14.3843 17.5493 14.5923 17.1333 14.5923Z" fill="#383838"/>
                            </svg>

                        </Link>
                        <Link variant="button" color="textPrimary" href="https://t.me/maptomind" className={classes.linkk}>
                            <p className={classes.linkFontStyle}>Мы в Telegram</p>

                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.iconstyle}>
                                <path d="M10.6882 0.322266C5.16823 0.322266 0.688232 4.80227 0.688232 10.3223C0.688232 15.8423 5.16823 20.3223 10.6882 20.3223C16.2082 20.3223 20.6882 15.8423 20.6882 10.3223C20.6882 4.80227 16.2082 0.322266 10.6882 0.322266ZM15.3282 7.12227C15.1782 8.70227 14.5282 12.5423 14.1982 14.3123C14.0582 15.0623 13.7782 15.3123 13.5182 15.3423C12.9382 15.3923 12.4982 14.9623 11.9382 14.5923C11.0582 14.0123 10.5582 13.6523 9.70823 13.0923C8.71823 12.4423 9.35823 12.0823 9.92823 11.5023C10.0782 11.3523 12.6382 9.02227 12.6882 8.81227C12.6952 8.78046 12.6943 8.74744 12.6855 8.71607C12.6768 8.6847 12.6606 8.65593 12.6382 8.63227C12.5782 8.58227 12.4982 8.60227 12.4282 8.61227C12.3382 8.63227 10.9382 9.56227 8.20823 11.4023C7.80823 11.6723 7.44823 11.8123 7.12823 11.8023C6.76823 11.7923 6.08823 11.6023 5.57823 11.4323C4.94823 11.2323 4.45823 11.1223 4.49823 10.7723C4.51823 10.5923 4.76823 10.4123 5.23823 10.2223C8.15823 8.95227 10.0982 8.11227 11.0682 7.71227C13.8482 6.55227 14.4182 6.35227 14.7982 6.35227C14.8782 6.35227 15.0682 6.37227 15.1882 6.47227C15.2882 6.55227 15.3182 6.66227 15.3282 6.74227C15.3182 6.80227 15.3382 6.98227 15.3282 7.12227Z" fill="#383838"/>
                            </svg>
                        </Link>
                        <Link variant="button" color="textPrimary" href="https://t.me/+dGY4I3-6Zrs1ZjRi" className={classes.linkk}>
                            <p className={classes.linkFontStyle}>Чат Telegram</p>

                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.iconstyle}>
                                <path d="M4.13943 12.1975H11.6402V10.3223H4.13943V12.1975ZM4.13943 9.38467H15.3906V7.50947H4.13943V9.38467ZM4.13943 6.57188H15.3906V4.69668H4.13943V6.57188ZM0.389038 19.6982V2.82149C0.389038 2.30581 0.572807 1.8642 0.940346 1.49666C1.30788 1.12912 1.74918 0.945666 2.26423 0.946291H17.2658C17.7815 0.946291 18.2231 1.13006 18.5906 1.4976C18.9582 1.86514 19.1416 2.30643 19.141 2.82149V14.0727C19.141 14.5883 18.9572 15.0299 18.5897 15.3975C18.2221 15.765 17.7808 15.9485 17.2658 15.9479H4.13943L0.389038 19.6982Z" fill="#383838"/>
                            </svg>
                        </Link>
                    </nav>
                    <div className={classes.headerLinksWrap}>
                        <Button 
                            component={Link}
                            className={classes.donateLink}
                            href="https://pay.cloudtips.ru/p/d46821b4"
                            target="_blank"
                            underline="none"
                        >
                            Поддержать проект ₽
                        </Button>
                    </div>
        </Toolbar>
      </AppBar>
      <div className={classes.containerWrap}>
        <div className={classes.mainContentWrap}>
          <h2>Подготовка к ЕГЭ вместе с <span className='text-red'>Maptomind</span></h2>
          <div className={classes.paragraphsWrap}>
            <p>При подготовке к ЕГЭ по географии особое внимание нужно уделить знанию географической карты. Нужно уметь по памяти находить на карте географические объекты, необходимые для решения задания.</p>
            <p>Мы подготовили набор заданий, которые содержат набор географических объектов, встречающихся в ЕГЭ.</p>
            <p>Просто прорешайте несколько раз каждое задание, пока ваш результат не достигнет стабильных 90+% и на экзамене всё получится!</p>
          </div>
          <div className={classes.chapters}>
            {!!chapter.length &&
              <div className={classes.mobileBackButton} onClick={handleClear}>
                <ArrowBack />
                <p>Вернуться<br />к разделам</p>
              </div>
            }
            <div className={classes.chaptersTitle}><span onClick={handleClear} className={!!chapter.length ? classes.choseChapter : ''}>Разделы</span>{!!chapter ? <><div className={classes.circle} /><div className={classes.chaptersNote}>{chapter}</div></> : null}</div>
            {
              !chapter &&
              <ul className={classes.chaptersWrap}>
                {chaptersList.map(item => (
                  <li key={item.id} onClick={() => setData(item)} className={`${classes.chapterWrap}${chapter === item.name ? ' activeChapter' : ''}`}>
                    <p>{item.name}</p>
                  </li>
                ))}
              </ul>
            }
            {
              !!subChapters &&
              <ul className={`${classes.subChaptersWrap} ${subChapters.length % 2 !== 0 ? 'odd' : ''}`}>
                {subChapters.map(subChapter => (
                  <li key={subChapter.id} className={classes.subChapterWrap}>
                    <a href={subChapter.link}>
                      <p>{subChapter.name}</p>
                    </a>
                  </li>
                ))}
                <div />
              </ul>
            }
          </div>
          <div className={classes.mapExample}>
            <p>Пример отображения теста для подготовки</p>
            <img src={desktopMapExample} alt='' className={classes.desktopImage}/>
            <img src={mobileMapExample} alt='' className={classes.mobileImage}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrepareForUse