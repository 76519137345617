import React from 'react';


const SoundIcon = ({ classname }) => {
  return (
    <svg
      className={classname}
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8026 6.09183C13.8026 6.09183 15.1553 6.76818 15.1553 8.45904C15.1553 10.1499 13.8026 10.8262 13.8026 10.8262M15.1553 2.03377C17.8607 3.38646 19.2134 5.41549 19.2134 8.45904C19.2134 11.5026 17.8607 13.5316 15.1553 14.8843M1.62842 5.41549V11.5026H5.01014L10.4209 15.5607V1.35742L5.01014 5.41549H1.62842Z"
        stroke="#6F6F6F"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SoundIcon;