
import {Control, Map, Util, DomEvent, DomUtil} from 'leaflet';
// import { SkipAnswer as ReactComponent } from "./missIcon.svg"


export var Progress = Control.extend({
    options: {
        // position: 'topright',
        position: 'topleft',
        time: '00:00',
        question: '',
        progress: '',
    },
    initialize: function (options) {
        Util.setOptions(this, options);
    },
    onAdd: function (map) {
        // if (!this.options.config.onboarding && this.options.config.objectView) {
        //     document.querySelector(".display-help").classList.remove("hidden")
        //     setTimeout(() => {
        //         document.querySelector(".display-help").classList.add("hidden")
        //     }, 1500)
        // }

        map.Progress = this;
        // this._toggleSoundWrap = DomUtil.create('div', "mobile-toggler-wrap-sound")
        // this._toggleSound = DomUtil.create('div', `control-sound ${!this.options.config.sound ? "off" : ""}`)
        // this._soundIconWrap = DomUtil.create('div', "sound-icon-wrap")
        // this._offLabelSound = DomUtil.create('span', "makeStyles-turnOff-175 label-off label-sound")
        // const soundIcon = `
        //     <svg
        //         width="21"
        //         height="17"
        //         viewBox="0 0 21 17"
        //         fill="none"
        //         xmlns="http://www.w3.org/2000/svg"
        //     >
        //         <path
        //             d="M13.8026 6.09183C13.8026 6.09183 15.1553 6.76818 15.1553 8.45904C15.1553 10.1499 13.8026 10.8262 13.8026 10.8262M15.1553 2.03377C17.8607 3.38646 19.2134 5.41549 19.2134 8.45904C19.2134 11.5026 17.8607 13.5316 15.1553 14.8843M1.62842 5.41549V11.5026H5.01014L10.4209 15.5607V1.35742L5.01014 5.41549H1.62842Z"
        //         stroke="currentColor"
        //         strokeWidth="1.7"
        //         strokeLinecap="round"
        //         strokeLinejoin="round"
        //         />
        //     </svg>
        // `;
        // this._soundIconWrap.innerHTML = soundIcon
        // this._soundIconWrap.appendChild(this._offLabelSound)
        // this._toggleSound.appendChild(this._soundIconWrap)
        // this._toggleSoundWrap.appendChild(this._toggleSound)
        // DomEvent.on(this._toggleSound, 'click', function (){
        //     this._toggleSound.classList.toggle("off")
        //     this.options.changeConfig("sound")
        // }, this);
        // this._toggleViewObjectWrap = DomUtil.create('div', "mobile-toggler-wrap-view-object")
        // this._toggleViewObject = DomUtil.create('div', `control-view-object ${!this.options.config.objectView ? "off" : ""}`)
        // this._viewObjIconWrap = DomUtil.create('div', "view-object-icon-wrap")
        // this._offLabelObject = DomUtil.create('span', "makeStyles-turnOff-175 label-off label-object")
        // const viewObjecIcon = `
        //     <svg
        //         width="20"
        //         height="15"
        //         viewBox="0 0 20 15"
        //         fill="none"
        //         xmlns="http://www.w3.org/2000/svg"
        //     >
        //         <path
        //             d="M10.0107 2.69767C11.6635 2.69217 13.2844 3.1532 14.6868 4.02776C16.0893 4.90232 17.2165 6.15491 17.9389 7.64151C16.4557 10.6707 13.4265 12.5854 10.0107 12.5854C6.59499 12.5854 3.56576 10.6707 2.08261 7.64151C2.80497 6.15491 3.93218 4.90232 5.33465 4.02776C6.73713 3.1532 8.35794 2.69217 10.0107 2.69767ZM10.0107 0.899902C5.51634 0.899902 1.67811 3.69542 0.123047 7.64151C1.67811 11.5876 5.51634 14.3831 10.0107 14.3831C14.5051 14.3831 18.3434 11.5876 19.8984 7.64151C18.3434 3.69542 14.5051 0.899902 10.0107 0.899902ZM10.0107 5.39431C10.6067 5.39431 11.1783 5.63107 11.5998 6.0525C12.0212 6.47393 12.2579 7.04552 12.2579 7.64151C12.2579 8.23751 12.0212 8.80909 11.5998 9.23053C11.1783 9.65196 10.6067 9.88872 10.0107 9.88872C9.41475 9.88872 8.84316 9.65196 8.42173 9.23053C8.0003 8.80909 7.76354 8.23751 7.76354 7.64151C7.76354 7.04552 8.0003 6.47393 8.42173 6.0525C8.84316 5.63107 9.41475 5.39431 10.0107 5.39431ZM10.0107 3.59655C7.78152 3.59655 5.96578 5.41229 5.96578 7.64151C5.96578 9.87074 7.78152 11.6865 10.0107 11.6865C12.24 11.6865 14.0557 9.87074 14.0557 7.64151C14.0557 5.41229 12.24 3.59655 10.0107 3.59655Z"
        //             fill="currentColor"
        //         />
        //     </svg>
        // `;
        // this._viewObjIconWrap.innerHTML = viewObjecIcon
        // this._viewObjIconWrap.appendChild(this._offLabelObject)
        // this._toggleViewObject.appendChild(this._viewObjIconWrap)
        // this._toggleViewObjectWrap.appendChild(this._toggleViewObject)
        // DomEvent.on(this._toggleViewObject, 'click', function (){
        //     this._toggleViewObject.classList.toggle("off")
        //     this.options.changeConfig("objectView")
        //     setTimeout(() => {
        //         const helper = document.querySelector(".display-help")
        //         if (helper) {
        //             helper.classList.add("hidden")
        //         }
        //     }, 1500)
        // }, this);

        this._container = DomUtil.create('div', 'leaflet-control leaflet-control-mtm menu-hide');
        this._questionContainer = DomUtil.create('div', 'question card card-btn');
        this._timerContainer = DomUtil.create('div', 'leaflet-control-Timer chip card-btn');

        this._timerWrapper = DomUtil.create('div', 'timer-wrapper');
        this._timerWrapper.appendChild(this._timerContainer);

        this._progressContainer = DomUtil.create('div', 'leaflet-control-Progress chip card-btn');

        this._progressWrapper = DomUtil.create('div', 'progress-wrapper');
        this._progressWrapper.appendChild(this._progressContainer);

        this._quitButton = DomUtil.create('button', 'quit card card-btn card_auto');

        this._escapeButton = DomUtil.create('button', 'escape card card-btn card_auto');
        this._escapeButtonForMobile = DomUtil.create('button', 'escape-mobile card card-btn card_auto');
        this._escapeButtonSvgWrap = DomUtil.create('div', 'escape-button-svg-wrap');
        const svgSkipAnswer = `<svg class="skip-answer" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.927734" width="46" height="46" rx="16" fill="white"/>
        <path d="M32.6995 34.3984C32.8715 35.2917 32.1061 35.6395 31.139 35.8373C30.1718 36.0352 29.3293 35.9812 29.1632 35.1059C28.997 34.2305 29.6616 33.4571 30.6406 33.2653C31.6196 33.0734 32.5155 33.4511 32.6995 34.3984Z" fill="#A276A9"/>
        <path d="M25.0275 27.5029C25.2827 28.804 24.624 30.033 23.562 30.2489C22.4999 30.4647 21.426 29.5774 21.1886 28.2764C20.9513 26.9753 21.598 25.7463 22.666 25.5364C23.734 25.3266 24.7961 26.2019 25.0394 27.5029H25.0275Z" fill="#A276A9"/>
        <path d="M32.7764 25.9447C33.0256 27.2097 34.0639 28.0731 35.1082 27.8692C36.1524 27.6654 36.7873 26.4663 36.544 25.1953C36.3008 23.9242 35.2506 23.0669 34.236 23.2767C33.2214 23.4866 32.5509 24.6797 32.7942 25.9447H32.7764Z" fill="#A276A9"/>
        <path d="M28.8369 41.3769C25.5965 41.3809 22.4522 40.2648 19.9261 38.2138C17.4001 36.1628 15.6444 33.3005 14.9505 30.1021C14.2566 26.9037 14.6662 23.5617 16.1115 20.631C17.5567 17.7003 19.9505 15.3574 22.8954 13.9912C25.8403 12.625 29.1591 12.3177 32.3007 13.1205C35.4422 13.9232 38.2175 15.7877 40.1657 18.4042C42.1139 21.0207 43.1177 24.2319 43.0105 27.5045C42.9033 30.7771 41.6915 33.9141 39.5763 36.3947C38.2489 37.96 36.6014 39.216 34.7474 40.0761C32.8934 40.9362 30.877 41.38 28.8369 41.3769ZM28.8369 15.2847C26.5362 15.2858 24.2874 15.9762 22.3749 17.2686C20.4624 18.5609 18.972 20.3972 18.092 22.5453C17.2121 24.6934 16.9822 27.0568 17.4313 29.3369C17.8805 31.617 18.9885 33.7114 20.6154 35.3553C22.2423 36.9992 24.315 38.1189 26.5714 38.5727C28.8279 39.0265 31.1669 38.7942 33.2928 37.9051C35.4186 37.016 37.2359 35.5099 38.5148 33.5774C39.7938 31.6449 40.477 29.3726 40.4782 27.0478C40.4751 23.929 39.2476 20.9388 37.0651 18.7335C34.8826 16.5282 31.9234 15.2878 28.8369 15.2847Z" fill="#A276A9"/>
        <path d="M5.8375 13.0855L3.87012 11.022C4.60138 10.0163 5.58303 9.22407 6.71536 8.72577C7.97235 8.14179 9.34141 7.84536 10.7248 7.8576V10.6005C9.80339 10.6072 8.89739 10.8404 8.08506 11.2799C7.21964 11.7171 6.45411 12.3321 5.8375 13.0855ZM10.2392 28.9201C9.67946 28.9201 9.14263 28.6954 8.74682 28.2954C8.351 27.8955 8.12864 27.353 8.12864 26.7874C8.12864 26.2218 8.351 25.6793 8.74682 25.2794C9.14263 24.8794 9.67946 24.6547 10.2392 24.6547C10.5171 24.6522 10.7927 24.7057 11.0498 24.8119C11.307 24.9182 11.5407 25.0752 11.7372 25.2738C11.9337 25.4723 12.089 25.7084 12.1942 25.9683C12.2994 26.2282 12.3523 26.5066 12.3498 26.7874C12.3526 27.0686 12.2989 27.3474 12.1919 27.6069C12.0848 27.8664 11.9268 28.1013 11.7272 28.2973C11.5346 28.4985 11.3031 28.6576 11.0472 28.7647C10.7912 28.8718 10.5162 28.9247 10.2392 28.9201ZM8.80726 22.0565V20.6725C8.78728 19.797 8.98871 18.9309 9.3925 18.1561C9.81084 17.3991 10.4156 16.7641 11.1482 16.3128C11.8304 15.9177 12.4206 15.3792 12.879 14.7337C13.2564 14.19 13.4586 13.5419 13.458 12.8779C13.4713 12.5597 13.4119 12.2427 13.2845 11.9515C13.1571 11.6602 12.9651 11.4024 12.7234 11.1982C12.1315 10.7615 11.4061 10.5499 10.675 10.6005V7.8576C12.2144 7.75555 13.7407 8.20064 14.9896 9.11581C15.5043 9.55557 15.9111 10.11 16.178 10.7355C16.4448 11.361 16.5644 12.0405 16.5274 12.7206C16.5361 13.7468 16.2623 14.7553 15.7367 15.6334C15.1299 16.584 14.3165 17.3823 13.3584 17.9673C12.8151 18.3057 12.3484 18.7558 11.9887 19.2885C11.6864 19.7537 11.5262 20.2984 11.528 20.8549V22.0377L8.80726 22.0565Z" fill="#A276A9"/>
        </svg>
        `

        this._escapeButtonSvgWrap.innerHTML = svgSkipAnswer
        this._escapeButtonForMobile.appendChild(this._escapeButtonSvgWrap)

        DomEvent.on(this._escapeButtonForMobile, 'click', function (){
            map.fire('noanswer');
        }, this);
        this._mobileEmptyLine0 = DomUtil.create('div', 'mtm-mobile-empty-line');
        this._line1 = DomUtil.create('div', 'mtm-line1');
        this._line2 = DomUtil.create('div', 'timer-block card');
        // this._configControls = DomUtil.create('div', 'config-wrapper');
        // this._configControls.appendChild(this._toggleSoundWrap);
        // this._configControls.appendChild(this._toggleViewObjectWrap);
        this._timerItemsWrap = DomUtil.create('div', 'timer-block-items-wrap')
        this._timerItem1 = DomUtil.create('div', 'timer-block-item');
        this._timerItem2 = DomUtil.create('div', 'timer-block-item');
        this._timerItem3 = DomUtil.create('div', 'timer-block-item');

        this._line3 = DomUtil.create('div', 'controls');
        this._line1.appendChild(this._questionContainer);

        this._timerItemText1 = DomUtil.create('span', 'timer-block-item-title');
        this._timerItem1.appendChild(this._timerItemText1);
        this._timerItem1.appendChild(this._timerWrapper);
        this._timerItemText1.innerHTML = 'Время';

        this._timerItemText2 = DomUtil.create('span', 'timer-block-item-title');
        this._timerItem2.appendChild(this._timerItemText2);
        this._timerItem2.appendChild(this._progressWrapper);
        this._timerItemText2.innerHTML = 'Выполнено';


        this._menuMobileIcon = DomUtil.create('div', 'timer-block-icon');
        const svg = `
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <defs>
        <filter id="shadow" height="120%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="0.1"/>
        </filter>
        </defs>
            <path d="M2.64732 0.582032L13.7188 11.832L24.7902 0.582031L27.2188 2.58203L13.7188 16.082L0.21875 2.58203L2.64732 0.582032Z" fill="#CDCDCD" fill-opacity="0.5" filter="url(#shadow)" transform="translate(0 -1.5)"/>
            <path d="M2.64732 11.9082L13.7188 23.1582L24.7902 11.9082L27.2188 13.9082L13.7188 27.4082L0.21875 13.9082L2.64732 11.9082Z" fill="#CDCDCD" fill-opacity="0.5" filter="url(#shadow)" transform="translate(0 -1.5)"/>
        </svg>
        `;
        this._menuMobileIcon.innerHTML = svg;
        this._line2.appendChild(this._menuMobileIcon);

        DomEvent.on(this._menuMobileIcon, 'click', function (){
            const item = document.querySelector('.leaflet-control-mtm');
            item.classList.toggle('menu-hide')
        }, this);

        this._menuMobileText = DomUtil.create('div', 'timer-block-menu');
        this._menuMobileText.innerHTML = 'Меню';
        this._line2.appendChild(this._menuMobileText);

        this._processLine = DomUtil.create('div', 'process-line');
        this._processLineInner = DomUtil.create('div', 'process-line-inner');
        this._processLineText = DomUtil.create('span', 'process-line-text');
        this._processLine.appendChild(this._processLineInner);
        this._processLine.appendChild(this._processLineText);
        this._processLineText.innerHTML = `0%`;

        this._timerItem3.appendChild(this._processLine);


        // this._line2.appendChild(this._timerItem1);
        // this._line2.appendChild(this._timerItem2);
        // this._line2.appendChild(this._timerItem3);
        this._timerItemsWrap.appendChild(this._timerItem1);
        this._timerItemsWrap.appendChild(this._timerItem2);
        this._timerItemsWrap.appendChild(this._timerItem3);
        this._line2.appendChild(this._timerItemsWrap);
        // this._line2.appendChild(this._configControls);

        this._line3.appendChild(this._quitButton);
        this._line3.appendChild(this._escapeButton);
        this._container.appendChild(this._mobileEmptyLine0)
        this._container.appendChild(this._line1);
        this._container.appendChild(this._line2);
        this._container.appendChild(this._line3);
        this._container.appendChild(this._escapeButtonForMobile);
        DomEvent.disableClickPropagation(this._container);

        this._questionContainer.innerHTML = this.options.question;
        const [current, all] = this.options.progress.split("/")
        const currProgress = `${current - 1}/${all}`
        this._progressContainer.innerHTML = currProgress;

        map.on('question', this.updateQuestion, this);

        this._escapeButton.innerHTML = this.options.button;
        DomEvent.on(this._escapeButton, 'click', function (){
            map.fire('noanswer');
        }, this);

        this._quitButton.innerHTML = this.options.quit;
        DomEvent.on(this._quitButton, 'click', function (){
            this._escapeButton.disabled = true;
            this._quitButton.disabled = true;
            map.fire('quit');
        }, this);

        this._timerContainer.innerHTML = this.options.time;
        map.on('tick', this.updateTime, this);

        return this._container;
    },
    onRemove: function (map) {
//        DomEvent.off(this._escapeButton, 'click'); // should I pass DomEvent
        map.off('question', this.updateQuestion, this);
        map.off('tick', this.updateTime, this);
    },
    updateQuestion: function (data) {
        this.options.question = data.question;
        this.options.progress = data.progress;
        this._questionContainer.innerHTML = this.options.question;
        // if (this.options.question && this.options.config.objectView) {
        //     document.querySelector(".display-help").innerHTML = this.options.question
        //     document.querySelector(".display-help").classList.remove("hidden")
        //     setTimeout(() => {
        //         document.querySelector(".display-help").classList.add("hidden")
        //     }, 1500)
        // }

        const [done, all] = this.options.progress.split('/');
        const currProgress = `${done - 1}/${all}`
        this._progressContainer.innerHTML = currProgress;

        const percent = Math.round(100 * (done - 1) / all);
        this._processLineText.innerHTML = `${done > 1 ? percent : 0}%`;
        this._processLineInner.style.width = `${percent}%`;

        return this;
    },
    updateTime: function (data) {
        this.options.time = data.time;
        this._timerContainer.innerHTML = this.options.time;
        return this;
    },
});

Map.mergeOptions({
    ProgressControl: false
});

Map.addInitHook(function () {
    if (this.options.ProgressControl) {
        new Progress().addTo(this);
    }
});

export var progress = function (options) {
    return new Progress(options);
};
