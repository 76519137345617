import Joyride from "react-joyride";
import './styles.css';
import { Step } from "./Step";
import {
  useCallback,
  useMemo,
  useState
} from "react";

const helpersIndexForClass = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh"]

const helpersHideMenu = [0, 1, 4, 5, 6]
const helpersOpenMenu = [2, 3]

const OnBoarding = ({ helpDisplay, onClose }) => {

  const [index, setIndex] = useState(0);

  const onAction = useCallback((action) => {
    if (action === 'prev') {
      setIndex(prev => prev - 1);
    }
    if (action === 'next') {
      setIndex(prev => prev + 1);
    }
    if (action === 'miss') {
      onClose();
    }
  }, [setIndex, onClose]);

  const helpers = useMemo(() => ({
    "mobile": [
      {
        target: '.question',
        content: (
          <Step isFirst onAction={onAction}>
            Объект, который требуется найти, отмечен вверху.
          </Step>
        ),
        disableBeacon: true,
        hideFooter: true,
      },
      {
        target: '.mtm-mobile-empty-line',
        // target: '.leaflet-control-mtm',
        content: (
          <Step onAction={onAction}>
            Внизу находится меню, которое можно вызвать свайпом вверх.
          </Step>
        ),
        disableBeacon: true,
        hideFooter: true,
      },
      {
        // target: '.config-wrapper',
        target: '.timer-block-items-wrap',
        content: (
          <Step onAction={onAction}>
            В меню показаны параметры: время, кол-во найденных объектов и шкала, показывающая текущий прогресс.
          </Step>
        ),
        disableBeacon: true,
        hideFooter: true,
      },
      {
        target: '.controls',
        content: (
          <Step onAction={onAction}>
            Под полузунками  располагается кнопка «Завершить», которая завершает текущую сессию.
          </Step>
        ),
        disableBeacon: true,
        hideFooter: true,
      },
      {
        target: '.escape-mobile',
        content: (
          <Step onAction={onAction}>
            Кнопка со смайлом означает «Не знаю», при нажатии на которую  текущий объект выделяется на карте, но <strong>не засчитывается</strong>, как найденный.
          </Step>
        ),
        disableBeacon: true,
        hideFooter: true,
      },
      {
        target: '.escape-mobile',
        content: (
          <Step onAction={onAction}>
            Для подтверждения, что вы поняли, где располагается объект,  нужно нажать на него.
          </Step>
        ),
        disableBeacon: true,
        hideFooter: true,
      },
      {
        target: '.leaflet-control-zoom',
        content: (
          <Step onAction={onAction}>
            Кнопки масштаба находятся в правой части экрана. Вы также можете масштабировать колесиком мыши.
          </Step>
        ),
        disableBeacon: true,
        hideFooter: true,
      },
    ],
    "desktop": [
      {
        target: '.question',
        content: (
          <Step isFirst onAction={onAction}>
              Объект, который требуется найти, отмечен в верхнем правом углу.
          </Step>
        ),
        disableBeacon: true,
        hideFooter: true,
      },
      {
        target: '.timer-block',
        content: (
          <Step onAction={onAction}>
            Ниже объекта поиска расположены параметры: время, кол-во найденных обектов и шкала, показывающая текущий прогресс.
          </Step>
        ),
        disableBeacon: true,
        hideFooter: true,
      },
      {
        target: '.quit',
        content: (
          <Step onAction={onAction}>
            Под параметрами находится кнопка «Завершить», которая завершает текущую сессию.
          </Step>
        ),
        disableBeacon: true,
        hideFooter: true,
      },
      {
        target: '.escape',
        content: (
          <Step onAction={onAction}>
            Справа от кнопки «Завершить» находится кнопка «Не знаю», при нажатии на которую  текущий объект выделяется на карте, но <strong>не засчитывается</strong>, как найденный.
          </Step>
        ),
        disableBeacon: true,
        hideFooter: true,
      },
      {
        target: '.leaflet-pane',
        content: (
          <Step onAction={onAction}>
            Для подтверждения, что вы поняли, где располагается объект,  нужно нажать на него.
          </Step>
        ),
        disableBeacon: true,
        hideFooter: true,
      },
      {
        target: '.leaflet-control-zoom',
        content: (
          <Step onAction={onAction}>
            Кнопки масштаба находятся в правой части экрана. Вы также можете масштабировать колесиком мыши.
          </Step>
        ),
        disableBeacon: true,
        hideFooter: true,
      },
    ]
  }), [onAction])

  const steps = useMemo(
    () => helpers[helpDisplay],
    [helpDisplay, helpers]
  )

  // const steps = useMemo(() => [
  //   {
  //     target: '.question',
  //     content: (
  //       <Step isFirst onAction={onAction}>
  //         { helpDisplay === "desktop"
  //           ? "Объект, который требуется найти, отмечен в верхнем правом углу."
  //           : "Объект, который требуется найти, отмечен вверху."
  //         }
  //       </Step>
  //     ),
  //     disableBeacon: true,
  //     hideFooter: true,
  //   },
  //   {
  //     target: '.timer-block',
  //     content: (
  //       <Step onAction={onAction}>
  //         Ниже объекта поиска расположены параметры: время, кол-во найденных обектов и шкала, показывающая текущий прогресс.
  //       </Step>
  //     ),
  //     disableBeacon: true,
  //     hideFooter: true,
  //   },
  //   {
  //     target: '.quit',
  //     content: (
  //       <Step onAction={onAction}>
  //         Под параметрами находится кнопка «Завершить», которая завершает текущую сессию.
  //       </Step>
  //     ),
  //     disableBeacon: true,
  //     hideFooter: true,
  //   },
  //   {
  //     target: '.escape',
  //     content: (
  //       <Step onAction={onAction}>
  //         Справа от кнопки «Завершить» находится кнопка «Не знаю», при нажатии на которую  текущий объект выделяется на карте, но <strong>не засчитывается</strong>, как найденный.
  //       </Step>
  //     ),
  //     disableBeacon: true,
  //     hideFooter: true,
  //   },
  //   {
  //     target: '.leaflet-pane',
  //     content: (
  //       <Step onAction={onAction}>
  //         Для подтверждения, что вы поняли, где располагается объект,  нужно нажать на него.
  //       </Step>
  //     ),
  //     disableBeacon: true,
  //     hideFooter: true,
  //   },
  //   {
  //     target: '.leaflet-control-zoom',
  //     content: (
  //       <Step onAction={onAction}>
  //         Кнопки масштаба находятся в правой части экрана. Вы также можете масштабировать колесиком мыши.
  //       </Step>
  //     ),
  //     disableBeacon: true,
  //     hideFooter: true,
  //   },
  // ], [onAction, helpDisplay]);
  const clickJoy = () => {
    const tooltip = document.querySelector(".__floater");
    if (tooltip) {
      tooltip.classList.add("custom-floater")
      tooltip.classList.add(helpersIndexForClass[index])
    } else {
      onClose()
    }
    if (helpersOpenMenu.includes(index)) {
      document.querySelector(".leaflet-control-mtm").classList.remove("menu-hide")
    } else if (helpersHideMenu.includes(index)) {
      document.querySelector(".leaflet-control-mtm").classList.add("menu-hide")
    }
  }

  return (
    <Joyride
      continuous
      run={true}
      steps={steps}
      stepIndex={index}
      hideCloseButton={true}
      disableOverlayClose
      disableScrolling={true}
      spotlightPadding={0}
      styles={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          zIndex: 10000,
        },
        spotlight: {
          borderRadius: '15px'
        },
        tooltip: {
          padding: 0,
          borderRadius: '15px',
        },
        options: {
          arrowColor: 'transparent',
          backgroundColor: '#FFFFFF',
          overlayColor: 'rgba(79, 26, 0, 0.4)',
          textColor: '#C5C5C',
          // width: 470,
          zIndex: 11000,
          boxShadow: '4px 4px 10px 3px rgba(0, 0, 0, 0.25)',
          borderRadius: '15px',
        }
      }}
      // floaterProps={{
      //   zIndex: 12000,
      //   top: "87% !important",
      //   floater: {
      //     top: '87% !important'
      //   },
      //   floaterOpen: {
      //     zIndex: 12000
      //   },
      //   floaterOpening: {
      //     zIndex: 12000
      //   },
      //   tooltip: {
      //     zIndex: 15000,
      //   }
      //   // styles: {
      //   //   height: "300px !important",
      //   //   color: "red !important"
      //   // }
      // }}
      callback={clickJoy}
    />
  )
};

export {
  OnBoarding
};
