import {Region} from "../Entity/Region";
import {RegionCollection} from "../Collection/RegionCollection";
import {getAllRegionsUrl} from "../Service/EndpointFilterService";

export class RegionRepository {
    /**
     * Regions are sorted by num
     * @param {Boolean} fetchFromCacheFirst - сначала пытается взять данные из кеша(хардкод), а если в кеше пусто, то вызывает API
     * @return {Promise}
     */
    getAll(fetchFromCacheFirst = false) {
        return fetch(getAllRegionsUrl(fetchFromCacheFirst))
            .then(response => response.json())
            .then(regionsData => regionsData.sort((a, b) => (a.num > b.num) ? 1 : ((b.num > a.num) ? -1 : 0)).map(regionData => new Region(regionData.region)))
            .then(regions => new RegionCollection(regions));
    }
}
