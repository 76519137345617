
export const NO_ANSWER = NaN;

export default class UserAnswer {
    constructor(gobject, attemptsNum) {
        this.gobject = gobject;
        this.attemptsNum = attemptsNum;
    }
    getScore() {
        if (this.noAnswerGiven()) {
            return 0;
        }

        return 3 - Math.min(this.attemptsNum - 1, 3);
    }
    getAttemptsNum() {
        return this.attemptsNum;
    }
    getName() {
        return this.gobject.getName();
    }
    noAnswerGiven() {
        return UserAnswer.isNoAnswerGiven(this.attemptsNum);
    }
    static isNoAnswerGiven(count) {
        return isNaN(count);
    }
}
