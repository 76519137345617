export let layers = [
    {
        name: 'relief',
        url: 'https://tilesm2m.website.yandexcloud.net/{z}/{x}/{y}.jpg',        
    },
    {
        name: 'maps_for_free',
        url: 'https://maps-for-free.com/layer/relief/z{z}/row{y}/{z}_{x}-{y}.jpg',
    },
    {
        name: 'staen_terrain',
        url: 'https://stamen-tiles.a.ssl.fastly.net/terrain-background/{z}/{x}/{y}.png',
    },
    {
        name: 'deane_kensok',
        url: 'https://tiles.arcgis.com/tiles/jIL9msH9OI208GCb/arcgis/rest/services/World_Relief_Map/MapServer/tile/{z}/{y}/{x}?blankTile=false',
    },
    {
        name: 'russia_conic_projection',
        url: 'https://storage.yandexcloud.net/tiles.maptomind.ru/tilesrussia/{z}/{x}/{y}.jpg'
    },
    {
        name: 'water',
        url: 'https://maps-for-free.com/layer/water/z{z}/row{y}/{z}_{x}-{y}.gif',
    },
    {
        name: 'admin',
        url: 'https://maps-for-free.com/layer/admin/z{z}/row{y}/{z}_{x}-{y}.gif',
    },
];

