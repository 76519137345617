import {Popup, Util} from 'leaflet';

export var Hint = Popup.extend({
    options: {
        closeOnClick: false,
        closeButton: false,
        closeOnEscapeKey: false,
        duration: 1000,
        className: 'leaflet-popup-hint',
    },

    initialize: function (options) {
        Util.setOptions(this, options);
        this._handler = null;
    },

    onAdd: function (map) {
        Popup.prototype.onAdd.call(this, map);
        this._handler = setTimeout(() => {
            map.closePopup(this);
        }, this.options.duration);
    },

    onFinish: function () {
        clearTimeout(this._handler);
    },
});