import {CategoryCollection} from "../Collection/CategoryCollection";

export const REGION_DEFAULT = 'Африка';

export class Region {
    /**
     *
     * @param {String} name
     * @param {CategoryCollection|null} collection
     */
    constructor(name, collection = null) {
        this.name = name;

        this.categories = collection ?? new CategoryCollection();
    }

    /**
     *
     * @param {CategoryCollection} collection
     */
    setCategories(collection) {
        this.categories = collection;
    }

    getCategories() {
        return this.categories;
    }
}
