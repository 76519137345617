import './App.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    // NavLink
} from "react-router-dom";

// import {
//     AppBar,
//     Toolbar,
//     Typography,
// } from "@material-ui/core";

import ModeEducation from './ModeEducation';
import ModeCustomFilter from './ModeCustomFilter';
import ModeTest from './ModeTest';
import ModeTestResults from './ModeTestResults';
import ModeQuickTest from './ModeQuickTest';
import PrepareForUse from './PrepareForUse';

function App() {
  return (
    <Router>
        <Switch>
            <Route exact path="/">
                <ModeEducation />
            </Route>
            <Route exact path="/podgotovka-k-ege">
                <PrepareForUse />
            </Route>
            <Route exact path="/l/:lcode/g/:gcode">
                <ModeCustomFilter />
            </Route>
            <Route exact path="/quick_test/:id">
                <ModeQuickTest />
            </Route>            
            <Route exact path="/t/:tid">
                <ModeTest />
            </Route>
            <Route exact path="/r/:tid">
                <ModeTestResults />
            </Route>
            <Route path="*">
                404
            </Route>
        </Switch>
    </Router>
  );
}

export default App;
