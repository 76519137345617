import {RegionRepository} from "../Repository/RegionRepository";
import {CategoryRepository} from "../Repository/CategoryRepository";
import {GobjectRepository} from "../Repository/GobjectRepository";
import {FilterService} from "../Service/FilterService";

export class FilterFactory {
    createFilterService() {
        const regionRepository = new RegionRepository();
        const categoryRepository = new CategoryRepository();
        const gobjectRepository = new GobjectRepository();

        return new FilterService(regionRepository, categoryRepository, gobjectRepository);
    }
}